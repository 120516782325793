import i18n from "i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";



i18n
    .use(Backend)
    // .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translations: {
                    SaoTomeandPrincipe: "Ministry of Health",
                    PreRegister: "Pre-Register",
                    Welcometo: "Welcome to ",
                    OnlineVaccinationService: "Online Vaccination Service",
                    Introduction: "This service was created to make the process of administering the Covid-19 vaccine in São Tomé and Príncipe more flexible. If you are eligible to receive the vaccine, pre-register now to be vaccinated.",
                    HomeButton1: "Pre-register for Covid-19 vaccination now!",
                    HomeButton2: "Access Data Portal",
                    Preregistrationform: "Pre-registration form",
                    advicePreReg: 'By clicking on "submit" you consent to the availability of your data for the purpose of being contacted by the Ministry of Health as part of the process of scheduling vaccination against Covid-19.',
                    Processing: "Processing...",
                    Commonquestions: "Common questions",
                    Whatarevaccines: "What are vaccines?",
                    accordionOne: "Vaccines are prepared substances that are given in childhood and at other ages to protect against serious and often fatal illnesses. By stimulating the body's natural defenses, vaccines prepare the body to fight disease faster and more effectively.",
                    Howdovaccineswork: "How do vaccines work?",
                    accordionTwo: "Vaccines help a person's defense system fight infections more efficiently by triggering the body's immune response to specific diseases. So if a virus or bacteria invades the body in the future, the immune system will already know how to fight it",
                    Arevaccinessafe: "Are vaccines safe?",
                    Yes: "Yes.",
                    accordionThree: " In the development and approval of vaccines against COVID-19, as for any other drug, their efficacy, safety and quality is guaranteed through clinical trials and rigorous evaluation by the European Medicines Agency. In clinical trials, tens of thousands of volunteers were vaccinated and compared with an identical number of unvaccinated volunteers. Vaccinated individuals were followed for vaccine safety for more than six weeks. This is the period in which common adverse effects usually appear after taking vaccines, and there has not been a frequency or severity of these effects that jeopardizes the safety of the vaccines.",
                    InformationSystemforEpidemiologicalSurveillance: "Information System for Epidemiological Surveillance",
                    adviceLogin: "If you have already been vaccinated, please enter the details below to continue.",
                    Phonenumber: "Username",
                    Incorrectaccessdata: "Incorrect access data",
                    Covid19vaccine: "Covid-19 vaccine",
                    CovidMessageCard: "The SARS-CoV-2 coronavirus is the etiologic agent of covid-19 disease.",
                    Downloaddigitalcertificate: "Download digital certificate",
                    VaccinationDate:"Vaccination Date",
                    VaccineReceived:"Vaccine Received",
                    VaccineManufacturer:"Vaccine Manufacturer",
                    Dosenumber:"Dose number",
                    Codigo:"Code"

                }
            },
            pt: {
                defaultLanguage: true,
                translations: {
                    SaoTomeandPrincipe: "República Democrática de São Tomé e Príncipe",
                    PreRegister: "Efectuar pré-Registo",
                    Login: "Entrar na minha conta",
                    Logout: "Sair da minha conta",
                    Welcometo: "Bem-vindo ao ",
                    OnlineVaccinationService: "Portal de Saúde",
                    Introduction: "Este serviço foi criado para flexibilizar o processo de administração da vacina contra Covid-19 em São Tomé e Príncipe.\n",
                    SubIntroduction: "Mais saúde, mais riqueza!",
                    HomeButton1: "Efectuar o pré-registo para a vacinação",
                    HomeButton2: "Aceder ao Portal de dados da Covid-19",
                    Select: "Selecione",
                    Preregistrationform: "Formulário de Pré-registo",
                    advicePreReg: 'Ao clicar em  "submeter"  consente a disponibilização dos seus dados a finalidade de ser contactado pelas entidades do Ministério da Saúde no âmbito do processo de agendamento da vacinação contra a Covid-19.',
                    Cancel: "Cancelar",
                    Processing: "A processar... Aguarde!",
                    Submit: "Continuar",
                    Commonquestions: "Perguntas frequentes",
                    Whatarevaccines: "O que são vacinas?",
                    accordionOne: "Vacinas são substância preparadas que são dadas na infância e em outras idades para proteger contra doenças graves e muitas vezes fatais. Ao estimular as defesas naturais do corpo, as vacinas preparam o organismo para combater a doença de maneira mais rápida e eficaz.",
                    Howdovaccineswork: "Como as vacinas funcionam?",
                    accordionTwo: " As vacinas ajudam o sistema de defesa da pessoa a combater infecções de maneira mais eficiente, provocando uma resposta imunológica do corpo a doenças específicas. Então, se um vírus ou bactéria invadir o corpo no futuro, o sistema imunológico já saberá como combatê-lo",
                    Arevaccinessafe: "As vacinas são seguras?",
                    Yes: "Sim. ",
                    accordionThree: "No desenvolvimento e aprovação das vacinas contra a COVID-19, tal como para qualquer outro medicamento, está garantida a sua eficácia, segurança e qualidade, através de ensaios clínicos e de uma avaliação rigorosa pela Agência Europeia de Medicamentos. Nos ensaios clínicos realizados, dezenas de milhares de voluntários foram vacinados e comparados com um número idêntico de voluntários não vacinados. Os indivíduos vacinados foram acompanhados em termos de segurança das vacinas, ao longo de mais de seis semanas. Este é o período em que habitualmente surgem efeitos adversos comuns após a toma de vacinas, não se tendo observado uma frequência ou gravidade destes efeitos que coloque em causa a segurança das vacinas.",
                    InformationSystemforEpidemiologicalSurveillance: "Sistema de Infomação Sanitária",
                    adviceLogin: "Introduza os detalhes que se seguem para continuar.",
                    Phonenumber: "Utilizador",
                    Reference: "Senha",
                    Continue: "Continuar",
                    Incorrectaccessdata: "Dados de acesso incorretos",
                    Covid19vaccine: "Vacina contra Covid-19",
                    CovidMessageCard: " O coronavírus SARS-CoV-2 é o agente etiológico da doença covid-19.",
                    Downloaddigitalcertificate: "Download digital certificate",
                    VaccinationDate:"Data da Vacinação",
                    VaccineReceived:"Vacina Recebida",
                    VaccineManufacturer:"Fabricante da Vacina",
                    Dosenumber:"Número da dose",
                    Codigo:"Codigo"

                }
            }
        },
        fallbackLng: 'pt',
        debug: true,
    
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false,

        interpolation: {
            escapeValue: false, // react already safes from xss
            formatSeparator: ","
        },

        react: {
            wait: true,
            useSuspense: false
        }
    });
export default i18n;