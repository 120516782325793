import React from 'react'
import './StickFooter.css'



function Footer() {
    return (
        <footer id="footer" >
    
        {/* <div className="card-footer">
          
        </div> */}

      </footer>
    
    )
}

export default Footer
