import React from 'react'
import './Slide.css'
import Background from '../img/img3.jpg';
import { Link } from 'react-router-dom'
import locationPdf from '../pdf/Brochura Locais de Vacinação.pdf'

function Slide(props) {
    const { t } = props
    return (
        <section id="hero">
            <div className="hero-container">
                <div id="heroCarousel" className="carousel slide carousel-fade" data-ride="carousel">

                    <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

                    <div className="carousel-inner" role="listbox">
                      
                            <div className="carousel-item active" style={{ backgroundImage: `url(${Background})` }}>
                            
                            <div className="carousel-container  allContent" >
                                    <div className="carousel-content intro"> 
                                    <h2 className="sliderText">{t("Welcometo")} <span>{t("OnlineVaccinationService")}</span></h2>
                                        <h5 className="sliderText">{t("Introduction")}</h5>
                                        {/* <Link className="btn btn-lg btn-success btSapce" to="/individual/pre-registo" block>{t("HomeButton1")}</Link> */}
                                        <br></br>
                                        <a className="btn btn-lg btn-danger" href="https://covid.ms.gov.st/st/" target="_blank" block>{t("HomeButton2")} </a>   

                                        <br></br><br></br>
                                        <h4 className="sliderText"><strong>{t("SubIntroduction")}</strong></h4>
                                        <br></br>                                
                                    </div>                            
                                </div>
                            </div>
                        
                    </div>
                    
                    
                </div>
                
                
                </div>
                
        </section>

    )
}

export default Slide
