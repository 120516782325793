import React, { useState, useEffect } from 'react'
import { Spin } from 'antd';
import { request, Acess } from '../settings'
import './perfil.css'
import config from '../config.json'

let data = []
if (localStorage.getItem("me")) {
    data = JSON?.parse(localStorage.getItem("me"))
}

let allData = []
function Perfil(props) {
    const { dataL, t } = props
    const [edit, setedit] = useState(false)
    const [allAttributes, setallAttributes] = useState([])
    const [loading, setloading] = useState(true)
    /* const [saving,  setsaving] = useState(false) */
    // const [allData, setallData] = useState([])

    data = dataL?.length > 0 ? dataL : data

    useEffect(() => {
        getVariebles()
    }, [data])

    function VerifyEdit() {
        if (edit) {
            setedit(false)
        } else {
            setedit(true)
        }
    }

    async function getVariebles() {
        let attributesRequest = await Promise.resolve(request().getAttributes());
        // console.log(attributesRequest.data.programTrackedEntityAttributes);
        attributesRequest.data.programs[0].programTrackedEntityAttributes.map((x) => {
            x.trackedEntityAttribute?.optionSet?.options?.splice(0, 0, { displayName: t('Select') })
            data[0]?.attributes.forEach(y => {
                if (y.attribute === x.trackedEntityAttribute.id) {
                    if (x.trackedEntityAttribute?.optionSet) {
                        for (const option of x.trackedEntityAttribute?.optionSet?.options) {
                            if (y.value === option.code) {
                                x.trackedEntityAttribute = ({ ...x.trackedEntityAttribute, "value": option.displayName })
                            }
                        }
                    } else
                        x.trackedEntityAttribute = ({ ...x.trackedEntityAttribute, "value": y.value })
                }
            })
        })
        setallAttributes(attributesRequest.data.programTrackedEntityAttributes)
        setloading(false)
    }

    function organize(value, i) {
        let attributes = [...allAttributes]
        attributes[i].trackedEntityAttribute = ({ ...attributes[i].trackedEntityAttribute, "value": value })
        setallAttributes(attributes)
    }

    async function postUser() {
        /* setsaving(true) */
        let attributes = [...allAttributes]
        let dataToPush = []
        attributes.forEach((x, i) => {
            if (x.trackedEntityAttribute?.value) {
                if (x.trackedEntityAttribute?.value === "" || x.trackedEntityAttribute?.value === t('Select')) {
                } else {
                    dataToPush.push({ 'attribute': x.trackedEntityAttribute.id, 'value': x.trackedEntityAttribute.value })
                }
            }
        });
        // let getMyIdUnique = await Promise.resolve(engine.query(getMyId));
        // dataToPush.push({ "attribute": "nGuQ2Xx3LSt", "value": getMyIdUnique.results.value })
        let formUser = ({
            "trackedEntityInstance": JSON?.parse(localStorage.getItem("me"))?.[0]?.trackedEntityInstance,
            "trackedEntityType": JSON?.parse(localStorage.getItem("me"))?.[0]?.trackedEntityType,
            "orgUnit": Acess().ou,
            "attributes": dataToPush
        })
        allData = formUser
        if (dataToPush.length > 1) {
            let postUserR = await Promise.resolve(request().postU(formUser));
            // console.log(postUserR)
            if (postUserR.data.httpStatusCode = 200) {
                /* setsaving(false) */
                localStorage.removeItem('me')
                localStorage.setItem("me", JSON.stringify([allData]))
                alert(t("Updated successfully"))
                VerifyEdit()
            } else {
                alert(t("Error while updating"))
                /* setsaving(false) */
            }
            /* setsaving(false) */
        } else {
            /* setsaving(false) */
        }
    }

    function verifyVisibility(id) {
        let a = true
        config.esconderMenu.forEach(x => {
            if (x === id) {
                a = false
            }
        });
        return a
    }

    function verifydisable(id) {
        let b = false
        config.desabledMenu.forEach(x => {
            if (x === id) {
                b = true
            }
        });
        return b
    }

    function verifyLng(element) {
        return element.trackedEntityAttribute.displayName
        // return element.trackedEntityAttribute.translations.filter(x => x.locale === lg)[0]?.value ? element.trackedEntityAttribute.translations.filter(x => x.locale === lg)[0]?.value : element.trackedEntityAttribute.displayName
    }


    if (loading) {
        return (
            <div className="spin-loader" >
                <Spin ></Spin>
            </div>
        )
    }
    // console.log(data)
    return (
        <div>
            <div class="row">
                <div className="card">
                    <div className="card-body">
                        {allAttributes.map((row, i) => {
                            let a = verifyVisibility(row.trackedEntityAttribute.id)
                            let b = verifydisable(row.trackedEntityAttribute.id)
                            return (
                                a ?
                                    <div>
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-5">
                                                    <strong>{verifyLng(row)}</strong>
                                                </div>
                                                <div className="col-sm-7 text-secondary">
                                                    <h7 className="mb-0">{row.trackedEntityAttribute?.value}</h7>
                                                </div>
                                            </div>
                                            <hr></hr>

                                        </div>
                                    </div>
                                    :
                                    null
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Perfil
