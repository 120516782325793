import React, { useState, useEffect } from 'react'
import { Perfil, Cards, HeaderAction, Table } from '../components'
import { Spin, Pagination } from 'antd';
import './menu.css'
import { request } from '../settings'
import { Link, useHistory } from 'react-router-dom'
import { format, subYears } from 'date-fns'
import { TesteCovid } from '../containers'
import { LinearProgress } from '@material-ui/core';
import attributesRequest from '../Attributes.json'

let IndicatorDataHeaders = []
let AllFields = []
let dados = []
let total = 0
let filtros = []

let allData = []

function Menu({ children, data, t, lg, dataL }) {
    const history = useHistory()
    const [header, setheader] = useState([])
    const [loading, setloading] = useState(true)
    const [fieldsHeader, setfieldsHeader] = useState([])
    const [page, setpage] = useState(1)
    const [pageSize, setpageSize] = useState(10)
    const [items, setitems] = useState([])
    const [updating, setupdating] = useState(true)
    const [startDate, setstartDate] = useState(format(new Date(), "yyyy-MM-dd"))
    const [endDate, setendDate] = useState(format(new Date(), "yyyy-MM-dd"))
    const [individual, setindividual] = useState(true)
    const [localidade, setlocalidade] = useState("")
    const [distrito, setdistrito] = useState("")

    useEffect(() => {
        if (history.location.pathname.split("/")[1] === "menu") {
            setindividual(false)
            getAttributes()
        } else if (history.location.pathname.split("/")[1] === "individual") {
            setindividual(true)
            setloading(false)
        }
    }, [history.location.pathname])


    async function getAttributes() {

        IndicatorDataHeaders = []

        AllFields = []
        //  console.log(eventsRequest.data.programs[0].programTrackedEntityAttributes)
        filtros = ["XWmHwMwPuHP:IN:Portal Web;Posto de Vacinacao;USSD", "IqS6tueCLmB:IN:SNS"]
        /*  if (localStorage.getItem("AllFields")) {
             AllFields = JSON.parse(localStorage.getItem("AllFields"))
             IndicatorDataHeaders = JSON.parse(localStorage.getItem("IndicatorDataHeaders"))
         } else { */

        /* const eventsRequest = await Promise.resolve(request().getAttributes()); */

        IndicatorDataHeaders.push({
            title: "Data do Pré-Registo",
            key: "created",
            dataIndex: "created",
            options: []
        })
        attributesRequest.programs[0].programTrackedEntityAttributes?.forEach(element => {
            if (element.trackedEntityAttribute.id === "WFPNbyFUPpj") {
                IndicatorDataHeaders.push({
                    title: "Posto de Vacinacao",
                    key: "orgUnit",
                    dataIndex: "orgUnit",
                    options: ""
                })

                /*  IndicatorDataHeaders.push({
                     title: "Posto de Vacinacao",
                     key: element.trackedEntityAttribute.displayName,
                     dataIndex: element.trackedEntityAttribute.displayName,
                     options: element.trackedEntityAttribute.optionSet?.options
                 }) */
                IndicatorDataHeaders.push({
                    title: "Data do Agendamento",
                    key: "data",
                    dataIndex: "data",
                    options: ""
                })
                IndicatorDataHeaders.push({
                    title: "Hora do Agendamento",
                    key: "hora",
                    dataIndex: "hora",
                    options: ""
                })
            }
            if (element.displayInList === true) {

                IndicatorDataHeaders.push({
                    title: element.trackedEntityAttribute.displayName,
                    key: element.trackedEntityAttribute.id,
                    dataIndex: element.trackedEntityAttribute.id,
                    options: element.trackedEntityAttribute.optionSet?.options
                })

            }
            if (element.searchable === true) {
                element.trackedEntityAttribute?.optionSet?.options?.splice(0, 0, { displayName: t('Select') + ' ' + element?.trackedEntityAttribute?.displayName })
                AllFields.push(element)
            }
        })
        /* } */
        if (!localStorage.getItem("AllFields")) {
            localStorage.setItem("IndicatorDataHeaders", JSON.stringify(IndicatorDataHeaders))
            localStorage.setItem("AllFields", JSON.stringify(AllFields))
        }
        setloading(false)
        setheader(IndicatorDataHeaders)
        setfieldsHeader(AllFields)
        getAlldata(page, pageSize, startDate, endDate, "")
    }

    async function getAlldata(pag, siz, start, end, ou) {
        const eventsRequest = await Promise.resolve(request().getSchedule(pag, siz, start, end, ou));
        let allTei = []
        let allScheduler = {}
        for (const event of eventsRequest?.data?.events) {
            allTei.push(event.trackedEntityInstance)
            allScheduler[event.trackedEntityInstance] = { data: event.dueDate, orgUnit: event.orgUnitName }
        }
        let teisRequest = []
        if (allTei.length > 0) {
            teisRequest = await Promise.resolve(request().getMyEvents(allTei.toString().replaceAll(',', ';')));
        } else {
            teisRequest = []
        }

        dados = []
        total = eventsRequest?.data?.pager?.total

        allData = Espera1(teisRequest?.data?.trackedEntityInstances, allScheduler)
        setitems(allData)
        setupdating(false)
    }

    function Espera1(teis, sche) {
        let g = []
        teis?.forEach(tei => {
            g.push(checkValue1(tei, sche))
        });
        return g
    }

    const checkValue1 = (tei, sche) => {
        let a = {}
        for (const attribute of tei.attributes) {
            a[attribute.attribute] = attribute.value
        }
        a.created = tei.created
        a.data = sche[tei.trackedEntityInstance].data && format(new Date(sche[tei.trackedEntityInstance].data), "yyyy-MM-dd")
        a.hora = sche[tei.trackedEntityInstance].data && new Date(sche[tei.trackedEntityInstance].data).getHours() + ':' + new Date(sche[tei.trackedEntityInstance].data).getMinutes()
        a.orgUnit = sche[tei.trackedEntityInstance].orgUnit

        return a
    }

    function getOptionSet(option) {
        return IndicatorDataHeaders?.find(x => x.key === "COVAC - Posto de Vacinacao").options.find(y => y.code === option)?.displayName
    }

    const onPageChange = (pages, size) => {
        setupdating(true)
        if (page === pages && size !== pageSize) {
            setpageSize(size)
            setpage(1)
            getAlldata(1, size, startDate, endDate, distrito)
        } else {
            setpageSize(size)
            setpage(pages)
            getAlldata(pages, pageSize, startDate, endDate, distrito)
        }
    }


    if (loading) {
        return (
            <div className="spin-loader" >
                <Spin ></Spin>
            </div>
        )
    }

    const onChangeFilter = (filter, startDat, endDat, distrit, localidad) => {
        // console.log(filter)
        setdistrito(distrit)
        setstartDate(format(new Date(startDat), "yyyy-MM-dd"))
        setendDate(format(new Date(endDat), "yyyy-MM-dd"))
        setupdating(true)
        filtros = ["XWmHwMwPuHP:IN:Portal Web;Posto de Vacinacao;USSD", "IqS6tueCLmB:IN:SNS"]

        filter.forEach(element => {
            if (element.trackedEntityAttribute.value) {
                if (element.trackedEntityAttribute.value == "Selecione " + element.trackedEntityAttribute.displayName) {
                } else filtros.push(element.trackedEntityAttribute.id + ":like:" + element.trackedEntityAttribute.value)
            }
        });
        setpageSize(10)
        setpage(1)
        getAlldata(1, 10, format(new Date(startDat), "yyyy-MM-dd"), format(new Date(endDat), "yyyy-MM-dd"), distrit)
    }
    return (
        <React.Fragment>
            <div className="container ">

                <div className="">
                    <div className="">
                        {individual ?
                            <>
                                <div className="col-md-3">
                                    <div className="row">
                                        <Cards t={t} dataL={dataL} />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <Perfil t={t} dataL={dataL} lg={lg} />
                                    <TesteCovid t={t} dataL={dataL} lg={lg} />
                                </div>
                            </>
                            :
                            <>
                                <HeaderAction fieldsHeader={fieldsHeader} t={t} lg={lg} onChangeFilter={onChangeFilter} />
                                <div className="card mt-4 mb-4">
                                    {updating && <LinearProgress />}
                                    <Table columns={header} data={allData} />
                                </div>
                                <Pagination
                                    showSizeChanger
                                    pageSize={pageSize}
                                    current={page}
                                    onChange={onPageChange}
                                    total={total}
                                />
                            </>
                        }
                    </div>
                </div>
                {!individual && <div class="button" >
                    <Link class="speed" to="pre-registo">+</Link>
                </div>
                }
            </div>
        </React.Fragment>
    )
}

export default Menu
