import { createContext } from 'react';

export const AuthContext = createContext();


// export function useAuth() {
//   return useContext(AuthContext);
// }
// export function Autenticated() {
//     console.log(useContext(AuthContext))
//     return useContext(AuthContext);
//   }