import React, { useState, useContext, useEffect } from 'react'
import { Form, Button, Alert, ButtonToolbar } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { request } from '../settings'
import { AuthContext } from '../containers/authContext';
import './Auth.css'
import logo from '../img/stpms.png'

function Login(props) {
    const { t } = props
    const history = useHistory()
    const { setAuthTokens, setlogindata, settype } = useContext(AuthContext)
    const [loading, setloading] = useState(false)
    const [codigo, setcodigo] = useState("")
    const [numero, setnumero] = useState("")
    const [show, setShow] = useState(false);
    const [individual, setindividual] = useState(true)
    const [message, setmessage] = useState("")

    useEffect(() => {
        if (history.location.pathname.split("/")[1] === "login") {
            setindividual(false)
        } else if (history.location.pathname.split("/")[1] === "individual") {
            setindividual(true)
        }
    }, [history.location.pathname, navigator.onLine])

    async function submitForm() {
        if (numero && codigo) {
            setloading(true)
            // console.log(individual)~
            /*  if (navigator.onLine) { */
            if (individual) {
                const levels = await Promise.resolve(request().getMe(numero, codigo));

                if (levels.data.trackedEntityInstances.length === 1) {
                    localStorage.setItem("me", JSON.stringify(levels.data.trackedEntityInstances))
                    setAuthTokens([{ num: numero, cod: codigo, type: "person" }])
                    setlogindata(levels.data.trackedEntityInstances)
                    history.push('/individual/menu')
                } else {
                    setShow(true)
                    setloading(false)
                    setmessage(t("Incorrectaccessdata"))
                }
            } else {

                const section = await Promise.resolve(request().getSection(numero, codigo)).catch(x => {
                    setShow(true)
                    setloading(false)
                });
                console.log(section)
                if (section?.data) {
                    localStorage.setItem("ou", section.data.organisationUnits[0].id)
                    setAuthTokens([{ num: numero, cod: codigo, type: "group" }])
                    setlogindata(section.data.organisationUnits[0].id)
                    history.push('/menu')
                } else {
                    setShow(true)
                    setloading(false)
                    setmessage(t("Incorrectaccessdata"))
                }
            }
            /*   } else {
                  setShow(true)
                  setloading(false)
                  setmessage("Erro aon salvar, não possui conexão com a internet, por favor verifique sua conexão")
              } */
        }
    }

    return (
        <React.Fragment>
            <div >
                <Form className="form-signin">
                    <div className="text-center mb-6">
                        <img className="mb-4" src={logo} alt="SIVE" height="100" />
                        <p className="h5 mb-3 font-weight-bold ">{t("InformationSystemforEpidemiologicalSurveillance")} </p>
                        <p>{t("adviceLogin")}</p>
                    </div>
                    {individual ?
                        <>
                            <div className="form-label-group">
                                <input className="form-control" required onChange={(e) => setnumero(e.target.value)} id="number" placeholder="Contacto" required="required" autoFocus />
                                <label htmlFor="number">Contacto</label>
                            </div>
                            <div className="form-label-group">
                                <input className="form-control" required type="code" onChange={(e) => setcodigo(e.target.value)} id="code" placeholder="Código" required="required" />
                                <label htmlFor="code">Referencia</label>
                            </div>
                        </>
                        : <>
                            <div className="form-label-group">
                                <input className="form-control" required onChange={(e) => setnumero(e.target.value)} id="username" placeholder="Usuário" required="required" autoFocus />
                                <label htmlFor="usename">{t("Phonenumber")}</label>
                            </div>
                            <div className="form-label-group">
                                <input className="form-control" required type="password" onChange={(e) => setcodigo(e.target.value)} id="password" placeholder="Senha" required="required" />
                                <label htmlFor="password">{t("Reference")} </label>
                            </div>
                        </>
                    }
                    <div className="container-fluid">
                        <div class="row">
                            <Button variant="success" type="submit" onClick={() => submitForm()} disabled={loading} size="lg" block>
                                {loading ?
                                    <>
                                        <span role="status" aria-hidden="true"></span>
                                        {t("Processing")}
                                    </>
                                    :
                                    t('Continue')
                                }
                            </Button>

                            <a href="/" class="btn btn-lg btn-default btn-block">{t("Cancel")}</a>

                        </div>

                    </div>
                    <br /> <br />
                    {(show) ?
                        <Alert variant="danger" className="text-center">
                            {message}
                        </Alert>
                        :
                        null
                    }

                </Form>


            </div>

        </React.Fragment>
    )
}

export default Login
