import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { Menu, TesteCovid } from '../containers'
import { Cards } from '../components'
import PrivateRoute from './PrivateRoute';

function MenuRoutes(props) {
    const { history, t } = props;
    return (
        <Router>
            <Menu history={history}>
                <Switch>
                    <PrivateRoute exact path="/menu">
                        <Cards t={t}/>
                    </PrivateRoute>
                    <Route exact path="/menu/vacinas">
                        <TesteCovid t={t}/>
                    </Route>
                    {/* <Route exact path="/menu/teste-TB">
                        <Cards/>
                    </Route>
                    <Route exact path="/menu/vacina-covid">
                        <Cards/>
                    </Route>
                    <Route exact path="/menu/vacina-famarela">
                        <Cards/>
                    </Route> */}
                </Switch>
            </Menu>
        </Router>
    )
}

export default MenuRoutes
