import React, { useEffect, useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import './navbar.css'
import logo from '../img/stp.png'
import { AuthContext } from '../containers/authContext';

function NavbarTela(props) {
    const { t } = props
    const { i18n } = useTranslation();
    const history = useHistory()
    const myStorage = window.sessionStorage;
    const [logState, setlogState] = useState(false)
    const { setlng } = useContext(AuthContext)
    const [lg, setlg] = useState(true)
    const [location, setlocation] = useState(false)

    const changeLanguage = (lng) => {
        setlg(lng === "en" ? true : false)
        setlng(lng)
        i18n.changeLanguage(lng);
    };
    useEffect(() => {
        if (history.location.pathname.split("/")[2] === "menu") {
            setlogState(true)
        } else
            if ((history.location.pathname === "/menu" && myStorage.getItem("token")) || (history.location.pathname === "/menu/vacinas" && myStorage.getItem("token")) || (history.location.pathname === "/pre-registo" && myStorage.getItem("token")) || (history.location.pathname.split("/")[1] === "comfirmacao" && myStorage.getItem("token"))) {
                setlogState(true)
            }
        if (history.location.pathname.split("/")[2] === "login" || history.location.pathname.split("/")[2] === "pre-registo" || history.location.pathname === "/login" || history.location.pathname === "/" || history.location.pathname.split("/")[1] === "individual" && history.location.pathname.split("/")[2] === "meu-codigo" || history.location.pathname.split("/")[2] === "confirm") {
            setlocation(true)
        }
    }, [history.location.pathname])

    function logout() {
        if (history.location.pathname.split("/")[2] === "menu") {
            myStorage.removeItem('person')
            localStorage.removeItem('me')
            history.push('/individual/login')
        } else {
            myStorage.removeItem('token')
            localStorage.removeItem('ou')
            history.push('/login')
        }
    }
    // console.log(history.location.pathname.split("/")[2])

    return (
        <React.Fragment>
            <nav id="header" class="navbar navbar-expand-md fixed-top navbar-light bg-light">
                <div class="container-fluid align-items-center">
                    <div className="ms-2 logo me-auto d-flex">
                        <Link to="/"><img width={40} src={logo} alt="" className="img-fluid" /></Link>
                        <h1 className="text-light"><Link to="/" ><span>{t("SaoTomeandPrincipe")}</span></Link></h1>
                    </div>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="me-4 " >
                        <ul class="navbar-nav mb-2 mb-lg-0 collapse navbar-collapse" id="navbarSupportedContent">
                            {!logState ?
                                location ?
                                    <span className="d-flex">
                                        <li className="nav-item d-flex">
                                            {/*  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Individual
                                            </a> */}
                                            {/*  <Link className="nav-link" to="/individual/pre-registo">Pré-registo individual</Link>
                                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li><Link className="dropdown-item" to="/individual/pre-registo">Pré-Registo</Link></li>
                                                <li><Link style={{ paddingRight: 14 }} className="dropdown-item" to="/individual/login" >{t("Login")}</Link></li>
                                            </ul> */}
                                        </li>
                                        <li className="nav-item d-flex">
                                            <Link className="nav-link" to="/login">Acesso Instituicional</Link>
                                            <LoginOutlined style={{ marginLeft: -5, marginTop: 12 }} />
                                        </li>
                                    </span>
                                    :
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/login">{t("Login")}<LoginOutlined style={{ marginLeft: 5 }} /></Link>
                                    </li>
                                :
                                <li className="nav-item">
                                    <Link className="nav-link" to="#" onClick={() => logout()}>{t("Logout")}<LogoutOutlined style={{ marginLeft: 5, marginTop: '100' }} /></Link>
                                </li>
                            }

                        </ul>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}
export default NavbarTela
