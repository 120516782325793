import React, { useEffect, useState } from 'react'
import { Form, Button, Col, Row, Container, Alert, Card, ListGroup, ListGroupItem } from 'react-bootstrap'
import { format } from 'date-fns'
import { useHistory, useParams } from "react-router-dom";
import { Spin, Space } from 'antd';
import { request, Acess } from '../settings'
import config from '../config.json'
import { ArrowBack, DateRangeOutlined } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import Table from './Table'
import './prereg.css'
import PrintIcon from '@material-ui/icons/Print';
import ErrorIcon from '@material-ui/icons/Error';
import attributesRequest from '../Attributes.json'
import ReactGA from 'react-ga';


let gravida = ""
let myOrgUnit = ""

let IndicatorDataHeaders = []
/* let attributesRequest = [] */
const days = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]
const months = [{ id: "01", value: "Janeiro" }, { id: "02", value: "Fevereiro" }, { id: "03", value: "Março" }, { id: "04", value: "Abril" }, { id: "05", value: "Maio" }, { id: "06", value: "Junho" }, { id: "07", value: "Julho" }, { id: "08", value: "Agosto" }, { id: "09", value: "Septembro" }, { id: "10", value: "Outubro" }, { id: "11", value: "Novembro" }, { id: "12", value: "Dezembro" }]
function PreRegisto(props) {
    const { t, lg, setmyAllData } = props
    const history = useHistory()
    const slug = useParams()
    const [allAttributes, setallAttributes] = useState([])
    const [loading, setloading] = useState(true)
    const [saving, setsaving] = useState(false)
    const [tetype, settetype] = useState("")
    const [showOption, setshowOption] = useState(false)
    const [permissao, setpermissao] = useState(false)
    const [provincia, setprovincia] = useState("")
    const [provincias, setprovincias] = useState([])
    const [distrito, setdistrito] = useState("")
    const [distritos, setdistritos] = useState([])
    const [localidade, setlocalidade] = useState("")
    const [localidades, setlocalidades] = useState([])
    const [loadingOU, setloadingOU] = useState(false)
    const [hidden, sethidden] = useState(true)
    const [message, setmessage] = useState("")
    const [yearPermition, setyearPermition] = useState(true)
    const [compony, setcompony] = useState(false)
    const [personSaving, setpersonSaving] = useState([])
    const [disabled, setdisabled] = useState(true)
    const [day, setday] = useState("")
    const [month, setmonth] = useState("")
    const [year, setyear] = useState("")
    const [daySche, setdaySche] = useState("")
    const [monthSche, setmonthSche] = useState("")
    const [yearSche, setyearSche] = useState("")
    const [individual, setindividual] = useState(false)
    const [lactanteOuGravida, setlactanteOuGravida] = useState("")
    const [validated, setValidated] = useState(false);
    const [isOffLine, setisOffLine] = useState(false)
    const [dayAvailableSche, setdayAvailableSche] = useState("")
    const [schedules, setschedules] = useState("")
    const [capacity, setcapacity] = useState("")
    const [detailsFaci, setdetailsFaci] = useState(true)



    useEffect(() => {
        if (localStorage.getItem("localpersonSaving")) setpersonSaving(JSON.parse(localStorage.getItem("localpersonSaving")))
        if (history.location.pathname.split("/")[1] === "individual") {
            setindividual(true)
        }
        name()
        if (history.location.pathname.split("/")[1] === "pre-registo") {
            setcompony(true)
            IndicatorDataHeaders = ([
                {
                    title: "Referecia",
                    key: "identifier",
                    dataIndex: "identifier",
                },
                {
                    title: "Nome",
                    key: "firstName",
                    dataIndex: "firstName",
                },
                {
                    title: "Local",
                    key: "facilityName",
                    dataIndex: "facilityName",
                },
                {
                    title: "Data",
                    key: "date",
                    dataIndex: "date",
                },
                {
                    title: "Hora",
                    key: "time",
                    dataIndex: "time",
                },
                {
                    title: 'Acções',
                    key: 'action',
                    render: (text, record) => (
                        <Space size="middle">
                            {/*  {record.status === 201 ? */}
                            <IconButton
                                /* onClick={() => window.open(window.location.origin + "/#/individual/meu-codigo/" + record.tei, "_blank")}  */
                                className="d-flex justify-content-end">
                                <PrintIcon style={{ fontSize: 12 }} size={"small"} />
                            </IconButton>
                            {/*   :
                                <ErrorIcon size={"small"} style={{ fontSize: 14 }} />
                            } */ }
                        </Space>
                    ),
                }])
        }
    }, [])

    function valData(y, m, d, type) {
        //dd/mm/aaaa
        if (y.length === 4 && d.length > 0 && m.length > 0) {
            let diffDate = Math.round((new Date(y + '-' + m + '-' + d) - new Date()) / (1000 * 60 * 60 * 24))

            if ((m == "01") || (m == "03") || (m == "05") || (m == "07") || (m == "08") || (m == "10") || (m == "12")) {//mes com 31 dias
                if ((d < "01") || (d > "31")) {
                    setmessage("Data inválida")
                    setpermissao(true)
                    sethidden(true)
                    setyearPermition(true)
                } else {
                    if (type) {
                        if (diffDate >= 0 || diffDate === -0) {
                            getDateAvailable(localidade, y + '-' + m + '-' + d)
                            setdetailsFaci(true)
                            setpermissao(false)
                            sethidden(true)
                            setyearPermition(true)
                        } else {
                            setmessage("Selecione uma data válida para a marcação")
                            setdetailsFaci(false)
                            setpermissao(true)
                            sethidden(true)
                            setyearPermition(true)
                        }
                    } else
                        organize(y + '-' + m + '-' + d, findAttributeIndex(Acess().dataNasc))
                }
            } else if ((m == "04") || (m == "06") || (m == "09") || (m == "11")) {//mes com 30 dias
                if ((d < "01") || (d > "30")) {
                    setmessage("Data inválida")
                    setpermissao(true)
                    sethidden(true)
                    setyearPermition(true)
                } else {
                    if (type) {
                        if (diffDate >= 0 || diffDate === -0) {
                            getDateAvailable(localidade, y + '-' + m + '-' + d)
                            setdetailsFaci(true)
                            setpermissao(false)
                            sethidden(true)
                            setyearPermition(true)
                        } else {
                            setmessage("Selecione uma data válida para a marcação")
                            setdetailsFaci(false)
                            setpermissao(true)
                            sethidden(true)
                            setyearPermition(true)
                        }
                    } else
                        organize(y + '-' + m + '-' + d, findAttributeIndex(Acess().dataNasc))
                }
            } else if ((m == "02")) {//February and leap y
                if ((y % 4 == 0) && ((y % 100 != 0) || (y % 400 == 0))) {
                    if ((d < "01") || (d > "29")) {
                        setmessage("Data inválida")
                        setpermissao(true)
                        sethidden(true)
                        setyearPermition(true)
                    } else {
                        if (type) {
                            if (diffDate >= 0 || diffDate === -0) {
                                getDateAvailable(localidade, y + '-' + m + '-' + d)
                                setdetailsFaci(true)
                                setpermissao(false)
                                sethidden(true)
                                setyearPermition(true)
                            } else {
                                setmessage("Selecione uma data válida para a marcação")
                                setdetailsFaci(false)
                                setpermissao(true)
                                sethidden(true)
                                setyearPermition(true)
                            }
                        } else
                            organize(y + '-' + m + '-' + d, findAttributeIndex(Acess().dataNasc))
                    }
                } else {
                    if ((d < "01") || (d > "28")) {
                        setmessage("Data inválida")
                        setpermissao(true)
                        sethidden(true)
                        setyearPermition(true)
                    } else {
                        if (type) {
                            if (diffDate >= 0 || diffDate === -0) {
                                getDateAvailable(localidade, y + '-' + m + '-' + d)
                                setdetailsFaci(true)
                                setpermissao(false)
                                sethidden(true)
                                setyearPermition(true)
                            } else {
                                setmessage("Selecione uma data válida para a marcação")
                                setdetailsFaci(false)
                                setpermissao(true)
                                sethidden(true)
                                setyearPermition(true)
                            }
                        } else
                            organize(y + '-' + m + '-' + d, findAttributeIndex(Acess().dataNasc))
                    }
                }
            } else {
                organize(y + '-' + m + '-' + d, findAttributeIndex(Acess().dataNasc))
            }
        } else {
            setpermissao(false)
            sethidden(true)
            setyearPermition(true)
        }
    }

    async function getOrgUnitChanger(value, type) {
        setloadingOU(true)
        if (type === "provincia") {
            let orgUnits = await Promise.resolve(request().getOrgUnits("level=district&parent=" + value))
            setprovincia(value)
            setdistritos(orgUnits.data.orgUnits)
            setloadingOU(false)
        } else if (type === "distrito") {
            let orgUnits = await Promise.resolve(request().getOrgUnits("level=facility&parent=" + value))
            setlocalidades(orgUnits.data.orgUnits)
            setloadingOU(false)
            setdistrito(value)
        } else if (type === "facility") {
            setlocalidade(value)
            setloadingOU(false)
            setdistrito(value)
        }

        /*  setloadingOU(true)
         let orgUnits = await Promise.resolve(request().getOrgUnits(value))
         if (type === "provincia") {
             setprovincia(value)
             setdistritos(orgUnits.data.children)
             setloadingOU(false)
         } else {
             setlocalidades(orgUnits.data.children)
             setloadingOU(false)
             setdistrito(value)
         } */
    }
    async function getDateAvailable(facility, date) {
        let paramiter;
        if (date) {
            paramiter = "date=" + date + "&facility=" + facility
        } else {
            paramiter = "facility=" + facility + "&useScheduleDate=true"
        }
        setloadingOU(true)
        let dateAvilable = await Promise.resolve(request().getAvailable(paramiter)).catch(x => {
            setloadingOU(false)
        });

        if (dateAvilable?.data?.status === 200) {
            console.log(dateAvilable?.data)
            let data = new Date(dateAvilable?.data?.schedules.length > 0 && dateAvilable?.data?.schedules[0]?.schedules[0]?.date).toISOString().slice(0, 10)
            let dia = data.split("-")[2]
            let mes = data.split("-")[1]
            let ano = data.split("-")[0]
            setcapacity(dateAvilable?.data.schedules[0]?.schedules[0]?.capacity)
            setschedules(dateAvilable?.data?.schedules[0]?.schedules[0]?.schedules)
            if (date) {
            } else {
                setdaySche(dia)
                setmonthSche(mes)
                setyearSche(ano)
            }
            setdayAvailableSche(data)
            setloadingOU(false)
        }
    }

    async function getSigleData(attributes) {
        /*   let attributesRequest = await Promise.resolve(request().getMyAttributes(slug.id)); */
        // attributes[i].trackedEntityAttribute = ({ ...attributes[i].trackedEntityAttribute, "value": value })
        // setallAttributes(attributes)
        /*   attributesRequest.attributes.forEach(element => {
              attributes.map((x) => {
                  if (element.attribute === x.trackedEntityAttribute.id) {
                      x.trackedEntityAttribute = ({ ...x.trackedEntityAttribute, "value": element.value })
                  }
              })
          });
  
          setallAttributes(attributes)
          setloading(false) */
    }

    async function name() {
        /* let orgUnits = await Promise.resolve(request().getOrgUnits(Acess().ou))
        setprovincias(orgUnits.data.children) */
        let orgUnits = await Promise.resolve(request().getOrgUnits("level=province")).catch(x => {
            console.log("bug")
        })
        /* console.log(orgUnits.data.orgUnits) */
        setprovincias(orgUnits?.data?.orgUnits)

        /* let attributesRequest = await Promise.resolve(request().getAttributes()); */
        /* settetype(attributesRequest.programs[0].trackedEntityType.id)*/

        attributesRequest.programs[0].programTrackedEntityAttributes.map((x) => {
            if (x.trackedEntityAttribute?.optionSet?.options[0].displayName === "") {
            } else x.trackedEntityAttribute?.optionSet?.options?.splice(0, 0, { displayName: "" })
        })

        if (slug.id) {
            getSigleData(attributesRequest.programs[0].programTrackedEntityAttributes)
        } else {
            setallAttributes(attributesRequest.programs[0].programTrackedEntityAttributes)
            setloading(false)
        }

    }

    function organize(value, i) {
        setpermissao(false)
        let attributes = [...allAttributes]
        attributes[i].trackedEntityAttribute = ({ ...attributes[i].trackedEntityAttribute, "value": value })
        if (attributes[i].trackedEntityAttribute.id === Acess().sexo || attributes[i].trackedEntityAttribute.id === Acess().dataNasc || attributes[i].trackedEntityAttribute.id === Acess().grupoAlvo) {
            vericarSexoEIdade(value, attributes[i].trackedEntityAttribute.displayName)
        }
        setallAttributes(attributes)
    }

    function verifyLng(element) {
        return element.trackedEntityAttribute.translations.filter(x => x.locale === lg)[0]?.value ? element.trackedEntityAttribute.translations.filter(x => x.locale === lg)[0]?.value : element.trackedEntityAttribute.displayName
    }

    function clearFields() {
        let attributes = [...allAttributes]
        for (let i = 0; i < attributes.length; i++) {
            attributes[i].trackedEntityAttribute.value = ""

        }
        sethidden(true)
        setyearPermition(true)
        setallAttributes(attributes)
    }

    function verifyLngOpSet(x) {
        return x?.translations?.filter(x => x.locale === lg)[0]?.value ? x?.translations?.filter(x => x.locale === lg)[0]?.value : x?.displayName
    }

    async function postUser() {
        setsaving(true)
        let attributes = [...allAttributes]
        let dataToPush = []

        attributes.forEach((x, i) => {
            if (x.trackedEntityAttribute?.value) {
                if (x.trackedEntityAttribute?.value === "" || x.trackedEntityAttribute?.value === t('Select')) {
                } else {
                    dataToPush.push({ 'attribute': x.trackedEntityAttribute.id, 'value': x.trackedEntityAttribute.value })
                }
            }
        });

        if (slug.id) {
            let form = ({
                "trackedEntityInstance": slug.id,
                "trackedEntityType": tetype,
                "orgUnit": distrito,
                "attributes": dataToPush
            })

            if (dataToPush.length > 1) {
                let postUserR = await Promise.resolve(request().postU(form));
                if (postUserR.data.httpStatusCode = 200) {
                    history.goBack()
                    setsaving(false)
                } else {
                    alert(t("Error saving"))
                    setsaving(false)
                }
                setsaving(false)
            } else {
                setsaving(false)
            }

        } else {
            let form = localidade && ({
                "resourceType": "Patient",
                "active": "true",
                "firstName": allAttributes[findAttributeIndex(Acess().nome)].trackedEntityAttribute?.value,
                "surname": allAttributes[findAttributeIndex(Acess().apelido)].trackedEntityAttribute?.value,
                "birthDate": allAttributes[findAttributeIndex(Acess().dataNasc)].trackedEntityAttribute?.value,
                "gender": allAttributes[findAttributeIndex(Acess().sexo)].trackedEntityAttribute?.value,
                "nationalIdType": allAttributes[findAttributeIndex(Acess().tipoDoc)].trackedEntityAttribute?.value,
                "nationalId": allAttributes[findAttributeIndex(Acess().numDoc)].trackedEntityAttribute?.value,
                "address": allAttributes[findAttributeIndex(Acess().endereco)].trackedEntityAttribute?.value,
                "usualWork": "",
                "telecom": [
                    {
                        "phone": allAttributes[findAttributeIndex(Acess().contacto)].trackedEntityAttribute?.value ? allAttributes[findAttributeIndex(Acess().contacto)].trackedEntityAttribute?.value : "",
                        "email": allAttributes[findAttributeIndex(Acess().email)].trackedEntityAttribute?.value
                    }
                ],
                "immunization": [
                    yearSche.length > 0 ?
                        {
                            "managingOrganization": localidade,
                            "scheduleDate": yearSche + "-" + monthSche + "-" + daySche
                        } :
                        {
                            "managingOrganization": localidade,
                        }
                ],
                "target": [
                    {
                        "primary": allAttributes[findAttributeIndex(Acess().grupoAlvo)].trackedEntityAttribute?.value,
                        "secondary": ""
                    }
                ],
                "comments": ""
            })

            let numer = allAttributes[findAttributeIndex(Acess().contacto)].trackedEntityAttribute?.value

            if (!isOffLine) {
                if (individual) {
                    let postUserR = await Promise.resolve(request().postU(form, "web_portal")).catch(x => {
                        setsaving(false)
                        setpermissao(true)
                        setmessage("Erro ao salvar, por favor tente novamente")
                        setTimeout(() => {
                            setpermissao(false)
                        }, 6000)
                    });
                    if (postUserR?.data?.status === 201) {
                        if (localStorage.getItem("time") && localStorage.getItem("myData")) {
                            localStorage.removeItem("myData")
                            localStorage.removeItem("time")
                        }
                        setmyAllData({ ...form, ...postUserR?.data?.details })
                        clearFields()
                        ReactGA.event({
                            category: 'Pré-Registo Individual',
                            action: 'Submissão',
                        });
                        history.replace("/individual/meu-codigo")
                    } else {
                        setpermissao(true)
                        setmessage("Erro ao gravar os dados!")
                        setsaving(false)
                        setTimeout(() => {
                            setpermissao(false)
                        }, 10000)
                    }
                } else {
                    let postUserR = await Promise.resolve(request().postU(form, "vaccination_site")).catch(x => {
                        setpermissao(true)
                        setmessage(x.message)
                        setsaving(false)
                    });

                    if (postUserR?.data?.status === 201) {
                        ReactGA.event({
                            category: 'Pré-Registo Instituicional',
                            action: 'Submissão',
                        });
                        setsaving(false)
                        setshowOption(false)
                        setlactanteOuGravida("")
                        setmonth("")
                        setday("")
                        setyear("")
                        clearFields()
                        setValidated(false);
                        getDateAvailable(localidade)
                        let localpersonSaving = []
                        if (personSaving) localpersonSaving = [...personSaving]
                        if (localpersonSaving.length === 3) {
                            localpersonSaving = []
                        } else {

                        }
                        localpersonSaving.push({ ...form, ...postUserR?.data?.details })
                        localStorage.removeItem("localpersonSaving")
                        localStorage.setItem("localpersonSaving", JSON.stringify(localpersonSaving))
                        setpersonSaving(localpersonSaving)
                    } else {
                        setpermissao(true)
                        setmessage(postUserR?.data?.message)
                        setsaving(false)
                        setTimeout(() => {
                            setpermissao(false)
                        }, 10000)
                    }
                }
                setsaving(false)
            } else {
                setpermissao(true)
                setmessage("Erro aon salvar, não possui conexão com a internet, por favor verifique sua conexão")
                setsaving(false)
                setTimeout(() => {
                    setpermissao(false)
                }, 20000)
            }
        }
    }

    function verifyVisibility(id) {
        let a = true
        config.esconderNewConfig.forEach(x => {
            if (x === id) {
                a = false
            }
        });
        return a
    }

    function vericarSexoEIdade(value, type) {
        if (type === "Sexo") {
            if (value === "Female") {
                setshowOption(true)
                setpermissao(false)
                sethidden(true)
            } else if (value === "Male") {
                gravida = "qualquercoisa"
                setshowOption(false)
                setpermissao(false)
                sethidden(false)
            } else if (value === "") {
                setshowOption(false)
                setpermissao(false)
                sethidden(true)
            }
        } else if (type === "Data de nascimento (idade)") {
            let categoria = allAttributes[findAttributeIndex(Acess().grupoAlvo)].trackedEntityAttribute?.value
            if (categoria === undefined || categoria === "") {
                setmessage("Por favor selecione a categoria")
                setpermissao(true)
                sethidden(true)
                setyearPermition(true)
            } else {
                var diff = Math.floor(new Date().getTime() - new Date(value).getTime());
                var day = 1000 * 60 * 60 * 24;

                var days = Math.floor(diff / day);
                var months = Math.floor(days / 31);
                var years = Math.floor(months / 12);
                if (categoria === "População de mais de 50 anos residente em áreas urbanas") {
                    /*    console.log(new Date(value).getFullYear() - new Date().getFullYear(), years < 49) */
                    if (new Date().getFullYear() - new Date(value).getFullYear() < 49) {
                        setmessage("Nesta fase, a vacina será a admnistrada à pessoas com 50 ou mais anos.")
                        setpermissao(true)
                        sethidden(true)
                        setyearPermition(true)
                    } else {
                        setyearPermition(false)
                        setpermissao(false)
                    }
                } else {
                    if (new Date().getFullYear() - new Date(value).getFullYear() < 17) {
                        setmessage("Pessoas com idade inferior à 18 anos não são elegíveis para a vacinação.")
                        setpermissao(true)
                        sethidden(true)
                        setyearPermition(true)
                    } else {
                        setyearPermition(false)
                        setpermissao(false)
                    }
                }
            }
        } else if (type === "Categoria") {
            let dataNasc = allAttributes[findAttributeIndex(Acess().dataNasc)].trackedEntityAttribute?.value
            if (value === undefined || value === "") {
                setmessage("Por favor selecione a categoria")
                setpermissao(true)
                sethidden(true)
                setyearPermition(true)
                setshowOption(false)
                setlactanteOuGravida("")
            } else {
                if (dataNasc === undefined || dataNasc === "") {
                    dataNasc = new Date()
                }
                var diff = Math.floor(new Date().getTime() - new Date(dataNasc).getTime());
                var day = 1000 * 60 * 60 * 24;

                var days = Math.floor(diff / day);
                var months = Math.floor(days / 31);
                var years = Math.floor(months / 12);
                if (value === "População de mais de 50 anos residente em áreas urbanas") {
                    /* console.log(new Date().getFullYear()-new Date(dataNasc).getFullYear(),years < 49)  */
                    if (years === 0 || years === NaN) {
                        sethidden(true)
                        setyearPermition(true)
                        setpermissao(false)

                    } else if (new Date().getFullYear() - new Date(dataNasc).getFullYear() < 50) {
                        setmessage("Nesta fase, a vacina será a admnistrada à pessoas com 50 ou mais anos.")
                        setpermissao(true)
                        sethidden(true)
                        setyearPermition(true)
                        setshowOption(false)
                    } else {
                        setyearPermition(false)
                        setpermissao(false)
                    }
                } else {
                    if (years === 0) {
                        sethidden(true)
                    } else if (new Date().getFullYear() - new Date(dataNasc).getFullYear() < 17) {
                        setmessage("Pessoas com idade inferior à 18 anos não são elegíveis para a vacinação.")
                        setpermissao(true)
                        sethidden(true)
                        setyearPermition(true)
                    } else {
                        setyearPermition(false)
                        setpermissao(false)
                    }
                }
            }
        }
    }

    function findAttributeIndex(id) {
        let attributes = [...allAttributes]
        for (let i = 0; i < attributes.length; i++) {
            if (attributes[i]?.trackedEntityAttribute?.id === id) {
                return i
            }
        }
    }

    function verificarPermissao(value) {
        if (value === "sim") {
            setmessage("Mulheres grávidas ou lactantes não são elegíveis para a vacina.")
            setpermissao(true)
            sethidden(true)
        } else if (value === "nao") {
            sethidden(false)
            setpermissao(false)
            setyearPermition(false)
        } else if (gravida === "") {
            sethidden(true)
            setpermissao(false)
        }
    }

    if (loading) {
        return (
            <div className="spin-loader" >
                <Spin size="large"></Spin>
            </div>
        )
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        } else {
            event.preventDefault();
            event.stopPropagation();
            postUser(form.checkValidity())
        }
    };

    return (
        <React.Fragment>
            <div className="topSpace ">
                <Container className="justify-content-center ">
                    <Row>
                        <Col className="col-lg-7 col-md-8 col-sm-12 col-12 mycontent-left">
                            <div className="d-flex titleSpace">
                                <IconButton style={{ marginTop: -10 }} onClick={() => history.goBack()}><ArrowBack /></IconButton>
                                {slug?.id ?
                                    <h3>
                                        Formulário de Edição
                                    </h3>
                                    :
                                    <h3>
                                        {t("Preregistrationform")}
                                    </h3>
                                }
                            </div>


                            <Form noValidate onSubmit={handleSubmit}>
                                {(history.location.pathname.split("/")[1] === "pre-registo") &&
                                    <>
                                        <h4 className="mb-">Posto de Vacinação</h4>
                                        <Form.Group as={Row} className="pb-2">
                                            <Form.Label column sm="4" className="goRight">Ilha</Form.Label>
                                            <Col sm="8" className="pb-2">
                                                <Form.Control isInvalid={validated && !provincia ? true : false} required as="select" value={provincia} onChange={(e) => getOrgUnitChanger(e.target.value, "provincia")}>
                                                    <option value="">Selecione a Ilha</option>
                                                    {provincias?.map((x) =>
                                                        <option key={x?.id} value={x?.id}>{x.name}</option>
                                                    )}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor preencha este campo
                                                </Form.Control.Feedback>
                                            </Col>
                                            {provincia &&
                                                <>
                                                    <Form.Label column sm="4" className="goRight">Distrito</Form.Label>
                                                    <Col sm="8" className="pb-2">
                                                        <Form.Control isInvalid={validated && !distrito ? true : false} required as="select" value={distrito} onChange={(e) => getOrgUnitChanger(e.target.value, "distrito")}>
                                                            <option value="">Selecione a Distrito</option>
                                                            {distritos?.map((x) =>
                                                                <option key={x?.id} value={x?.id}>{x.name}</option>
                                                            )}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            Por favor preencha este campo
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </>
                                            }
                                            {distrito &&
                                                <>
                                                    <Form.Label column sm="4" className="goRight">Posto de vacinação</Form.Label>
                                                    <Col sm="8" className="pb-2">
                                                        <Form.Control isInvalid={validated && !localidade ? true : false} required as="select" required value={localidade} onChange={(e) => (setlocalidade(e.target.value, "facility"), myOrgUnit = e.target.value, setpermissao(false), getDateAvailable(e.target.value))}>
                                                            <option value="">Selecione a Posto de vacinação</option>
                                                            {localidades?.map((x) =>
                                                                <option key={x?.id} value={x?.id}>{x.name}</option>
                                                            )}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            Por favor preencha este campo
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </>
                                            }

                                            {localidade.length > 0 && dayAvailableSche.length > 0 && <>
                                                <Form.Label column sm="4" className="goRight">Data para a vacinação</Form.Label>
                                                <Col sm="8" className="pb-2">
                                                    <div className="d-flex">
                                                        <Form.Control
                                                            isInvalid={validated && !daySche ? true : false}
                                                            id="validationCustom01"
                                                            required
                                                            as="select" className="me-2" value={daySche} onChange={(e) => { valData(yearSche, monthSche, e.target.value, true); setdaySche(e.target.value) }}>
                                                            <option value="">Dia</option>
                                                            {days.map(x =>
                                                                <option key={x} value={x}>{x}</option>
                                                            )}
                                                        </Form.Control>
                                                        <Form.Control
                                                            isInvalid={validated && !monthSche ? true : false}
                                                            id="validationCustom01"
                                                            required
                                                            as="select" className="me-2" value={monthSche} onChange={(e) => { valData(yearSche, e.target.value, daySche, true); setmonthSche(e.target.value) }}>
                                                            <option value="">Mês</option>
                                                            {months.map(x =>
                                                                <option key={x.id} value={x.id}>{x.value}</option>
                                                            )}
                                                        </Form.Control>
                                                        <Form.Control
                                                            // isInvalid={validated && !year ? true : false}
                                                            // required
                                                            placeholder={"Ano"}
                                                            type={"text"}
                                                            value={yearSche}
                                                            isInvalid={validated && !/(18[789]\d|19[0-9]{2}|20[02]\d)/.test(yearSche) ? true : false}
                                                            /*  pattern="^(18|20)\d{2}$" */
                                                            onChange={(e) => { valData(e.target.value, monthSche, dayAvailableSche, true); setyearSche(e.target.value) }}
                                                        />
                                                    </div>
                                                    <Form.Control.Feedback type="invalid">
                                                        Por favor preencha este campo
                                                    </Form.Control.Feedback>
                                                </Col>
                                                {detailsFaci &&
                                                    <>
                                                        <Form.Label column sm="4" className="goRight"></Form.Label>
                                                        <Col sm="4" >
                                                            <Alert variant="secondary">
                                                                Capacidade: {capacity}
                                                            </Alert>
                                                        </Col>
                                                        <Col sm="4">
                                                            <Alert variant="success">
                                                                Ocupação: {schedules}
                                                            </Alert>
                                                        </Col>
                                                    </>
                                                }
                                            </>}


                                            {loadingOU && <div className="m-2 text-center"><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></div>}
                                        </Form.Group>
                                        <h4 className="mb-">Elegibilidade</h4>
                                    </>
                                }


                                <Form.Group as={Row} className="pb-2">
                                    <Form.Label column sm="4" className="goRight">{allAttributes[findAttributeIndex(Acess().grupoAlvo)]?.trackedEntityAttribute?.displayName}</Form.Label>
                                    <Col sm="8" className="pb-2">
                                        <Form.Control isInvalid={validated && !allAttributes[findAttributeIndex(Acess().grupoAlvo)]?.trackedEntityAttribute?.value ? true : false} required as="select"
                                            id="validationCustom01"
                                            value={allAttributes[findAttributeIndex(Acess().grupoAlvo)]?.trackedEntityAttribute?.value}
                                            onChange={(e) => organize(e.target.value, findAttributeIndex(Acess().grupoAlvo))}>
                                            {/*   {allAttributes[findAttributeIndex(Acess().grupoAlvo)].trackedEntityAttribute?.optionSet.options?.map((x) =>
                                                <option key={x?.code} value={x?.code}>{x.displayName}</option>
                                            )} */}
                                            {allAttributes[findAttributeIndex(Acess().grupoAlvo)]?.trackedEntityAttribute?.optionSet.options?.map((x, ind) => {
                                                return (
                                                    <option key={x?.code} value={ind === 0 ? "" : x?.code}>{ind === 0 ? "Selecione " + allAttributes[findAttributeIndex(Acess().grupoAlvo)]?.trackedEntityAttribute.displayName : verifyLngOpSet(x)}</option>
                                                )
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Por favor preencha este campo
                                        </Form.Control.Feedback>
                                    </Col>

                                    <Form.Label column sm="4" className="goRight">Data de nascimento</Form.Label>
                                    <Col sm="8" className="pb-2">
                                        <div className="d-flex">
                                            <Form.Control
                                                isInvalid={validated && !day ? true : false}
                                                id="validationCustom01"
                                                required
                                                as="select" className="me-2" value={day} onChange={(e) => { valData(year, month, e.target.value); setday(e.target.value) }}>
                                                <option value="">Dia</option>
                                                {days.map(x =>
                                                    <option key={x} value={x}>{x}</option>
                                                )}
                                            </Form.Control>
                                            <Form.Control
                                                isInvalid={validated && !month ? true : false}
                                                id="validationCustom01"
                                                required
                                                as="select" className="me-2" value={month} onChange={(e) => { valData(year, e.target.value, day); setmonth(e.target.value) }}>
                                                <option value="">Mês</option>
                                                {months.map(x =>
                                                    <option key={x.id} value={x.id}>{x.value}</option>
                                                )}
                                            </Form.Control>
                                            <Form.Control
                                                // isInvalid={validated && !year ? true : false}
                                                // required
                                                placeholder={"Ano"}
                                                type={"text"}
                                                value={year}
                                                isInvalid={validated && !/(18[789]\d|19[0-9]{2}|20[02]\d)/.test(year) ? true : false}
                                                /*  pattern="^(18|20)\d{2}$" */
                                                onChange={(e) => { valData(e.target.value, month, day); setyear(e.target.value) }}
                                            />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Introduza data de nascimento válida
                                        </Form.Control.Feedback>
                                    </Col>
                                    {!yearPermition ?
                                        <>
                                            <Form.Label column sm="4" className="goRight d-none d-sm-block">Sexo</Form.Label>
                                            <Col sm="8" className="pb-2">
                                                <Form.Control
                                                    isInvalid={validated && !allAttributes[findAttributeIndex(Acess().sexo)].trackedEntityAttribute?.value ? true : false}
                                                    id="validationCustom01" required as="select" onChange={(e) => organize(e.target.value, findAttributeIndex(Acess().sexo))}>
                                                    <option value="">Selecione o Sexo</option>
                                                    <option key={"Male"} value={"Male"}>Masculino</option>
                                                    <option key={"Female"} value={"Female"}>Feminino</option>
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor preencha este campo
                                                </Form.Control.Feedback>
                                            </Col>
                                        </>
                                        :
                                        null
                                    }
                                </Form.Group>


                                {showOption ?
                                    //TODO @Edson Remove all occurences of lactante, as it is not necessaty anymore
                                    <Form.Group as={Row} className="pb-2">
                                        <Form.Label column sm="4" className="goRight d-none d-sm-block">Está grávida ou amamentar?</Form.Label>
                                        <Col sm="8" className="pb-2">
                                            <Form.Control isInvalid={validated && !lactanteOuGravida ? true : false} value={lactanteOuGravida} required as="select" onChange={(e) => { verificarPermissao(e.target.value); setlactanteOuGravida(e.target.value) }}>
                                                <option value="">Está grávida ou amamentar?</option>
                                                <option key={"sim"} value={"sim"}>Sim</option>
                                                <option key={"nao"} value={"nao"}>Não</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Por favor preencha este campo
                                            </Form.Control.Feedback>
                                        </Col>

                                    </Form.Group>
                                    : null}


                                {!hidden ?
                                    <>
                                        <h4 className="mb-3 topSpace">Identificação pessoal</h4>
                                        {allAttributes.map((row, i) => {
                                            let a = verifyVisibility(row.trackedEntityAttribute.id)
                                            let controlId = "validationCustom03" + i
                                            return (
                                                a ?
                                                    <Form.Group as={Row} className="pb-2" controlId={controlId}>
                                                        <Form.Label for="validationCustom01" column sm="4" className="goRight d-none d-sm-block">{row.trackedEntityAttribute.displayName}</Form.Label>
                                                        {/* <label  class="form-label">First name</label> */}
                                                        {row.trackedEntityAttribute?.optionSet?.options ?
                                                            <Col sm="8">
                                                                <Form.Control as="select"
                                                                    required
                                                                    onChange={e => organize(row.trackedEntityAttribute.valueType === "INTEGER_ZERO_OR_POSITIVE" || row.trackedEntityAttribute.valueType === "PHONE_NUMBER" ? e.target.value < 0 ? "" : e.target.value : e.target.value, i)}
                                                                    value={row.trackedEntityAttribute?.value}
                                                                    key={row.trackedEntityAttribute.id}
                                                                    name={row.trackedEntityAttribute.id}
                                                                    isInvalid={validated && !row.trackedEntityAttribute?.value ? true : false}
                                                                >
                                                                    {row.trackedEntityAttribute?.optionSet?.options.map((x, ind) => {
                                                                        return (
                                                                            <option key={x?.code} value={ind === 0 ? "" : x?.code}>{ind === 0 ? "Selecione " + row.trackedEntityAttribute.displayName : verifyLngOpSet(x)}</option>
                                                                        )
                                                                    })}
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Por favor preencha este campo
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                            :
                                                            row.trackedEntityAttribute.valueType === "AGE" ?
                                                                <Col sm="8">
                                                                    <Form.Control
                                                                        required
                                                                        onChange={e => organize(format(new Date(e.target.value), "yyyy-MM-dd"), i)}
                                                                        key={row.trackedEntityAttribute.id}
                                                                        value={row.trackedEntityAttribute?.value}
                                                                        isInvalid={validated && !row.trackedEntityAttribute?.value ? true : false}
                                                                        type={"DATE"} formTarget={"yyyy-MM-dd"}
                                                                        placeholder={row.trackedEntityAttribute.displayName}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Por favor preencha este campo
                                                                    </Form.Control.Feedback>
                                                                </Col>
                                                                :
                                                                row.trackedEntityAttribute.valueType === "TRUE_ONLY" ?
                                                                    <Col sm="8">
                                                                        <Form.Check
                                                                            required
                                                                            onChange={e => organize(e.target.value === 'on' ? true : "", i)}
                                                                            type="checkbox"
                                                                            key={row.trackedEntityAttribute.id}
                                                                            isInvalid={validated && !row.trackedEntityAttribute?.value ? true : false}
                                                                            // label="Remember my preference"

                                                                            custom />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            Por favor preencha este campo
                                                                        </Form.Control.Feedback>
                                                                    </Col>
                                                                    :
                                                                    <Col sm="8">
                                                                        <Form.Control
                                                                            required
                                                                            onChange={e => organize(row.trackedEntityAttribute.valueType === "INTEGER_ZERO_OR_POSITIVE" || row.trackedEntityAttribute.valueType === "PHONE_NUMBER" ? e.target.value < 0 ? "" : e.target.value : e.target.value, i)}
                                                                            key={row.trackedEntityAttribute.id}
                                                                            value={row.trackedEntityAttribute?.value}
                                                                            type={row.trackedEntityAttribute.valueType === "INTEGER_ZERO_OR_POSITIVE" || row.trackedEntityAttribute.valueType === "PHONE_NUMBER" ? "number" : row.trackedEntityAttribute.valueType === "DATE" ? "date" : "text"}
                                                                            placeholder={row.trackedEntityAttribute.displayName}
                                                                            isInvalid={validated && !row.trackedEntityAttribute?.value ? true : false} />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            Por favor preencha este campo
                                                                        </Form.Control.Feedback>
                                                                    </Col>
                                                        }

                                                    </Form.Group>
                                                    :
                                                    null
                                            )
                                        })}

                                        {(history.location.pathname.split("/")[1] !== "pre-registo") &&
                                            <>
                                                <h4 className="mb-3 topSpace">Local de vacinação</h4>
                                                <Form.Group as={Row} className="pb-2">
                                                    <Form.Label column sm="4" className="goRight d-none d-sm-block">Ilha</Form.Label>
                                                    <Col sm="8" className="pb-2">
                                                        <Form.Control isInvalid={validated && !provincia ? true : false} required as="select" value={provincia} onChange={(e) => getOrgUnitChanger(e.target.value, "provincia")}>
                                                            <option value="" >Selecione a Ilha</option>
                                                            {provincias?.map((x) =>
                                                                <option key={x?.id} value={x?.id}>{x.name}</option>
                                                            )}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            Por favor preencha este campo
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    {provincia &&
                                                        <>
                                                            <Form.Label column sm="4" className="goRight d-none d-sm-block">Distrito</Form.Label>
                                                            <Col sm="8" className="pb-2">
                                                                <Form.Control isInvalid={validated && !distrito ? true : false} required as="select" value={distrito} onChange={(e) => getOrgUnitChanger(e.target.value, "distrito")}>
                                                                    <option value="">Selecione a Distrito</option>
                                                                    {distritos?.map((x) =>
                                                                        <option key={x?.id} value={x?.id}>{x.name}</option>
                                                                    )}
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Por favor preencha este campo
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </>
                                                    }
                                                    {distrito &&
                                                        <>
                                                            <Form.Label column sm="4" className="goRight d-none d-sm-block">Posto de vacinação</Form.Label>
                                                            <Col sm="8" className="pb-2">
                                                                <Form.Control isInvalid={validated && !localidade ? true : false} required as="select" required value={localidade} onChange={(e) => (setlocalidade(e.target.value, "facility"), myOrgUnit = e.target.value, setpermissao(false))}>
                                                                    <option value="">Selecione a Localidade</option>
                                                                    {localidades?.map((x) =>
                                                                        <option key={x?.id} value={x?.id}>{x.name}</option>
                                                                    )}
                                                                </Form.Control>
                                                                <Form.Text>Selecione o local de acordo com a sua facilidade de acesso</Form.Text>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Por favor preencha este campo
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </>
                                                    }
                                                    {loadingOU && <div className="m-2 text-center"><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></div>}
                                                </Form.Group>
                                            </>
                                        }

                                        <h4 className="mb-3 topSpace">Informação de contacto</h4>
                                        <Form.Group as={Row} className=" pb-2">
                                            <Form.Label column sm="4" className="goRight d-none d-sm-block">{allAttributes[findAttributeIndex(Acess().contacto)].trackedEntityAttribute?.displayName}</Form.Label>

                                            <Col sm="8" className="pb-2">

                                                <Form.Control
                                                    required={individual ? true : false}
                                                    onChange={e => organize(e.target.value, findAttributeIndex(Acess().contacto))}
                                                    key={"text"}
                                                    value={allAttributes[findAttributeIndex(Acess().contacto)].trackedEntityAttribute?.value}
                                                    isInvalid={individual ? validated && !/^[0-9]{7}$/.test(allAttributes[findAttributeIndex(Acess().contacto)].trackedEntityAttribute?.value) ? true : false : false}
                                                    type={"text"}
                                                    /* pattern="^[8]{1}[2-7]{1}[0-9]{7}$" */
                                                    placeholder={allAttributes[findAttributeIndex(Acess().contacto)].trackedEntityAttribute?.displayName}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Introduza um contacto válido
                                                </Form.Control.Feedback>
                                            </Col>

                                            <Form.Label column sm="4" className="goRight d-none d-sm-block">{allAttributes[findAttributeIndex(Acess().email)].trackedEntityAttribute?.displayName}</Form.Label>
                                            <Col sm="8" className="pb-2">
                                                <Form.Control
                                                    onChange={e => organize(e.target.value, findAttributeIndex(Acess().email))}
                                                    key={"email"}
                                                    value={allAttributes[findAttributeIndex(Acess().email)].trackedEntityAttribute?.value}
                                                    type={"email"}
                                                    placeholder={"Email"}
                                                // isInvalid={validated && !/^([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4})$/.test(allAttributes[findAttributeIndex(Acess().email)].trackedEntityAttribute?.value) ? true : false}
                                                /* pattern={"/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/"} */
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Introduza um email válido
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                    </>

                                    : null}
                                {(permissao) ?
                                    <Alert variant="danger" className="text-center mt-3">
                                        {message}
                                    </Alert>
                                    :
                                    null
                                }

                                <div >
                                    {t("advicePreReg")}
                                </div>

                                <div className="goRight bottomSpace" >
                                    <br></br>

                                    <Button variant="outline-danger">{t("Cancel")}</Button>{' '}
                                    <button className="btn btn-primary" type="submit" disabled={permissao}>
                                        {saving ?
                                            <>
                                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                {individual ? "Continuar" : t("Processing")}
                                            </>
                                            :
                                            individual ? "Continuar" : t('Submit')
                                        }
                                    </button>
                                </div>
                            </Form>
                        </Col >

                        <Col className="col-lg-4 col-md-4 col-sm-12 col-12">
                            {compony ?
                                <>
                                    <h3>
                                        Últimos registos
                                    </h3>
                                    {personSaving.map((row, i) => {
                                        let a = verifyVisibility(row.identifier)
                                        return (
                                            a ?
                                                <Card className="mb-3">
                                                    <Card.Body>
                                                        <Card.Subtitle className="mb-2 text-muted">
                                                            <span>{row.firstName + " " + row.surname}</span>
                                                            <IconButton size="small" style={{ float: 'right', marginTop: '-5px', marginRight: '-5px' }} onClick={() => window.open(window.location.origin + "/#/comfirmacao/" + i, "_blank")}>
                                                                <PrintIcon size="small" />
                                                            </IconButton>
                                                        </Card.Subtitle>
                                                        <Card.Title>{row.identifier}</Card.Title>
                                                    </Card.Body>
                                                    <ListGroup className="list-group-flush">
                                                        <ListGroupItem>{row.facilityName}</ListGroupItem>
                                                        <ListGroupItem>{row.date}</ListGroupItem>
                                                        <ListGroupItem>{row.time}</ListGroupItem>
                                                    </ListGroup>
                                                </Card>

                                                :
                                                null
                                        )
                                    })}
                                </>

                                :
                                <>
                                    <h3>
                                        {t("Commonquestions")}
                                    </h3>

                                    <div>

                                        <div class="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <Button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        {t("Whatarevaccines")}
                                                    </Button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body text-justify">
                                                        <p className="text-justify">
                                                            {t("accordionOne")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        {t("Howdovaccineswork")}
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body text-justify">
                                                        {t("accordionTwo")}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        {t("Arevaccinessafe")}
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body text-justify">
                                                        <strong>{t("Yes")}</strong>
                                                        {t("accordionThree")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </Col>
                    </Row >
                </Container >
            </div >
        </React.Fragment>
    )
}

export default PreRegisto
