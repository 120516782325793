import React from 'react';
import { Route, Redirect } from 'react-router-dom';


function PrivateRoute({ component: Component, layout: Layout, data, t, lg, ...rest }) {
    const myStorage = window.sessionStorage;
    return (
        <Route
            {...rest}
            render={props =>
                // <LayoutP t={t} {...props}>
                <Layout t={t} {...props} lg={lg} data={data}>
                    {myStorage.getItem("token") && localStorage.getItem('ou')?.length > 0 ? (
                        <Component t={t} lg={lg} {...props} dataL={data} />
                    ) : (
                        <Redirect to="/login" />
                    )}
                </Layout>
                // </LayoutP>
            }
        />
    );


}

export default PrivateRoute;