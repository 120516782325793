import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';


function PrivateRoute({ component: Component, layout: Layout, t, ...rest }) {

    const myStorage = window.sessionStorage;
    return (
        <Route {...rest} render={props =>
            <Layout t={t} {...props}>
                {myStorage.getItem("token") && localStorage.getItem('ou')?.length > 0 ? (
                    <Redirect to="/menu" />
                ) : (
                    <Component t={t} {...props} />
                )}
            </Layout>
        } />
    );


}

export default PrivateRoute;