import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'


function LoginFaild(props) {
    const [show, setShow] = useState(false);

    if (show) {
        return (
            <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                <Alert.Heading>Error!</Alert.Heading>
                <p>
                    Código ou Numero invalido
                </p>
            </Alert>
        );
    }
}

export default LoginFaild
