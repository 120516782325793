import React from 'react'
// import '../App.css'

function Spinner() {
    return (
        <div className="center">
            <div className="spinner-grow d-flex align-items-center" role="status" aria-hidden="true"></div>
        </div>
    )
}

export default Spinner
