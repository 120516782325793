import React from 'react';
import { useTranslation } from 'react-i18next';
import { Slide } from '../components'
import './app.css'
function Application({ children }) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div className="application">
                <Slide t={t} />
            </div>
        </React.Fragment>
    )
}

export default Application