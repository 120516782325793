import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Spin } from 'antd';
import { Acess, request, baseUrl } from '../../../settings'
import { Table } from '../../../components'
import './covid.css'


const contacto = Acess().contacto
const codigo = Acess().codigo
let data = []
if (localStorage.getItem('me')) {
  if (localStorage.getItem('me').length > 0) {
    data = JSON.parse(localStorage.getItem('me'))?.[0]?.attributes;
  }
} else {
  data = []
}

function Covid(props) {
  const { dataL, t } = props
  const [loading, setloading] = useState(true)
  const [myEvents, setmyEvents] = useState([])
  const [grid, setgrid] = useState(false)
  const [myTei, setmyTei] = useState("")

  data = dataL?.length > 0 ? dataL[0]?.attributes : data

  useEffect(() => {
    PullEvents()
  }, [data])

  function verifytoGrid() {
    if (grid) {
      setgrid(false)
    } else {
      setgrid(true)
    }
  }

  console.log(data)
  async function PullEvents() {
    let events = []
    let cont = ""
    let cod = ""

    data?.forEach(element => {
      if (contacto === element.attribute) {
        cont = element.value
      }
      if (codigo === element.attribute) {
        cod = element.value
      }
    });
    // const eventsRequest = 'await Promise.resolve(request().getMyEvents(cont, cod))'
    const eventsRequest = await Promise.resolve(request().getMyEvents(cont, cod));
    let q1 = ""
    let q2 = ""
    let q3 = ""
    let q4 = ""
    let q5 = ""
    console.log(eventsRequest, cont, cod)
    eventsRequest.data.trackedEntityInstances.forEach(element => {
      setmyTei(element.trackedEntityInstance)
      element.enrollments.forEach(elem => {

        elem?.events?.forEach(ele => {
          q1 = ""
          q2 = ""
          q3 = ""
          q4 = ""
          if (ele.status === "SCHEDULE") {
            q5 = null
          } else
            q5 = ele.eventDate

          // console.log(ele.eventDate)
          if (ele.programStage === Acess().pStageVacina) {
            ele.dataValues.forEach(el => {

              if (el.dataElement === Acess().vacinaRecebida) {
                // console.log(el.value)
                q1 = el.value
              }
              if (el.dataElement === Acess().vaccineManuf) {
                // console.log(el.value)
                q2 = el.value
              }
              if (el.dataElement === Acess().doseNumbVacina) {
                // console.log(el.value)
                q3 = el.value
              }
              if (el.dataElement === Acess().sugestao) {
                // console.log(el.value)
                q4 = el.value
              }

            });
          }
          if (!(q1 === "" && q2 === "" && q3 === "" && q4 === "" && q5 === null)) {
            events.push({ vacinaRecebida: q1, vaccineManuf: q2, doseNumbVacina: q3, sugestao: q4, dataVacinacao: q5 && format(new Date(q5), 'yyyy-MM-dd') })
          }

        });
      });
    });
    setloading(false)
    setmyEvents(events)
  }

  if (loading) {
    return (
      <div className="spin-loader" >
        <Spin ></Spin>
      </div>
    )
  }

  function printExam(tei) {
    let baseUrl = ""
    if (process.env.NODE_ENV === 'production') {
      baseUrl = 'https://portal-stp.herokuapp.com';
    } else {
      baseUrl = 'https://portal-stp.herokuapp.com'
    }
    window.open(baseUrl + "?teis=" + tei, "_blank")
  }
  const columns = [
    {
      title: t('VaccinationDate'),
      key: 'Data da Vacinação',
      dataIndex: 'dataVacinacao',
    },
    {
      title: t('VaccineReceived'),
      key: 'Vacina Recebida',
      dataIndex: 'vacinaRecebida',
    },
    {
      title: t('VaccineManufacturer'),
      key: 'Fabricante da Vacina',
      dataIndex: 'vaccineManuf',
    },
    {
      title: t('Dosenumber'),
      key: 'Número da dose',
      dataIndex: 'doseNumbVacina',
    },

  ]
  console.log(myEvents)
  return (
    <div>
      {!grid ?
        <div className="table-result card mt-4" >
          <Table columns={columns} data={myEvents} />
        </div>
        :
        <section id="icon-boxes" className="icon-boxes">
          <div className="container ">
            <div className="row">
              {myEvents.length > 0 ?
                myEvents.map((x, i) =>
                  <>
                    <div className="col d-none d-lg-block ">
                      <div className="" data-aos="fade-up">
                        <div className="icon-box">
                          <h4 className="col-0 description"><span className="title">Numero da Dose: </span><b>{x.doseNumbVacina}</b></h4>
                          <h4 className="description"><span className="title">Data da Vacinação: </span><b> {x.dataVacinacao}</b></h4>
                          <h4 className="col-0 description"><span className="title">Nome da Vacina Recebida: </span><b> {x.vacinaRecebida}</b></h4>
                          <h4 className="col-0 description"><span className="title">Sugestão para a proxima Vacina: </span><b> {x.sugestao}</b></h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d-lg-none mt-4 ">
                      <div className="" data-aos="fade-up">
                        <div className="icon-box">
                          <h4 className="col-0 description"><span className="title">Numero da Dose: </span><b>{x.doseNumbVacina}</b></h4>
                          <h4 className="description"><span className="title">Data da Vacinação: </span><b> {x.dataVacinacao}</b></h4>
                          <h4 className="col-0 description"><span className="title">Nome da Vacina Recebida: </span><b> {x.vacinaRecebida}</b></h4>
                          <h4 className="col-0 description"><span className="title">Sugestão para a proxima Vacina: </span><b> {x.sugestao}</b></h4>
                        </div>
                      </div>
                    </div>
                  </>
                )

                :

                <div className="mt-4 col-md-12 text-center"><b>Sem dados para listar</b></div>
              }
            </div>
          </div>
        </section>
      }
    </div>
  )
}

export default Covid
