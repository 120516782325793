import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorkerRegistration';

let uiLanguage = 'pt';
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App uiLocale={uiLanguage} />
  </I18nextProvider>,
  document.getElementById('root')
);

serviceWorker.register();

reportWebVitals();
