import React from 'react'
import { Table, Spin } from 'antd';

function Tables(props) {
    const { columns, data, loading } = props
    if (loading) {
        return (
            <div className="spin-loader" >
                <Spin ></Spin>
            </div>
        )
    }
    return (
        <Table columns={columns} dataSource={data} pagination={false} size="small" />
    )
}

export default Tables
