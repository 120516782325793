import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { Navbar, Footer, NavbarMobile } from '../components'
import { Menu } from 'antd'
import { Menu as MenuIcon, Timeline } from '@material-ui/icons';
import './app.css'
import 'antd/dist/antd.css';
// let current = "menu"
let location = true
function Application({ children }) {
    const { t } = useTranslation();
    const [current, setcurrent] = useState()

    useEffect(() => {
        setcurrent("menu")
        if (window.location.pathname === "/") {
            location = false
        }
    }, [])

    return (
        <React.Fragment>
            <div className="application">
                <div className="hidden-device">
                    <Navbar t={t} />
                </div>
                <div className="hidden-pc">
                    <NavbarMobile t={t} />
                </div>

                <div style={{ paddingTop: 54 }} />
                {children}
            </div>
        </React.Fragment>
    )
}

export default Application
