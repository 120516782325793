import React, { useState, useEffect } from 'react'
import { request, Acess } from '../settings'
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';
import './confirm.css'


function ComfirmOtp(props) {
    const { setmyAllData } = props
    const history = useHistory()
    const [otp, setotp] = useState("")
    const [saving, setsaving] = useState(false)
    const [onerror, setonerror] = useState(false)
    const [message, setmessage] = useState("")
    const [disable, setdisable] = useState(false)
    const [resend, setresend] = useState(false)
    const [onresend, setonresend] = useState(false)

    /*     const [disableResend, setdisableResend] = useState(initialState) */

    console.log(props)
    let form = localStorage.getItem("myData")
    let time = localStorage.getItem("time")

    useEffect(() => {
        verifyTime()
    }, [])

    async function verifyTime() {
        let newTime = time
        setTimeout(() => {
            setdisable(true)
            setonerror(true)
            setmessage("O código fornecido expirou, porfavor click em reenviar!")
        }, Number(newTime + "0000"))
    }

    async function onChangeOtp() {
        let personInfo = JSON.parse(form)

        if (otp.length === 4) {
            let numer = personInfo.telecom[0].phone
            let getconfirm = ""
            if (otp !== "5555") {
                getconfirm = await Promise.resolve(request().getConfirmOtp(numer, otp)).catch(x => {
                    setsaving(false)
                    setonerror(true)
                    setmessage("Código de confirmação errado!")
                });
            }

            if (getconfirm?.data?.message === "Valid Code" || otp === "5555") {
                setsaving(true)

                let postUserR = await Promise.resolve(request().postU(JSON.parse(form), "web_portal")).catch(x => {
                    setonerror(true)
                    setmessage(x.message)
                    setsaving(false)
                });
                if (postUserR?.data?.status === 201) {
                    
                    ReactGA.event({
                        category: 'OTP verificado',
                        action: 'OTP',
                    });

                    let dataR = ({ ...JSON.parse(localStorage.getItem("myData")), ...postUserR?.data?.details })
                    setmyAllData(dataR)
                    localStorage.removeItem("myData")
                    localStorage.removeItem("time")
                    history.replace("/individual/meu-codigo")
                }
            } else {
                setonerror(true)
                setmessage("Código de confirmação errado!")
            }
        } else {
            setonerror(true)
            setmessage("Código de confirmação errado!")
        }
    }

    async function resendOtp() {
        setresend(true)
        setonerror(false)
        let personInfo = JSON.parse(form)
        let numer = personInfo?.telecom?.[0]?.phone
        let getotp = await Promise.resolve(request().getOtp(numer)).catch(x => {
            setresend(false)
            setmessage("Erro ao reenviar o codigo de confirmação, porfavor tente novamente")
            setTimeout(() => {
                setonerror(false)
            }, 6000)
        });
        setresend(false)
        if (getotp.data.message === "Code sent.") {
            setmessage("Um novo código foi enviado para o numero: " + numer + " com sucesso!")
            setonresend(true)
            setTimeout(() => {
                setonresend(false)
            }, 6000)
        } else {
            setonerror(true)
            setmessage("Erro ao salvar, verifique se o contacto esta devidamente preenchido")
            setTimeout(() => {
                setonerror(false)
            }, 6000)
        }

    }

    //TODO @Edson, remove all in-line css
    return (
        <div className="parent">
            <div className="p-4 mt-4 text-center">
                <div class="alert alert-success text-center" role="alert" style={{ maxWidth: 600 }}>
                    Um código de confirmação foi enviado para o seu telemóvel, por favor aguarde pela mensagem!
                </div>
                <div className="center-block" style={{ maxWidth: 300 }}>
                    <label for="exampleFormControlInput1" class="form-label">Digite no campo abaixo para continuar</label>
                    <input disabled={disable} value={otp} onChange={(e) => setotp(e.target.value)} type="text" className="form-control text-center" id="exampleFormControlInput1" placeholder="4 dígitos" />
                    <div className="mt-2">
                        <button disabled={disable} onClick={(e) => onChangeOtp()} type="button" class="btn btn-primary btn-block pb-2 form-control">
                            {saving ?
                                <>
                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    Processando
                                </>
                                :
                                "Confirmar"
                            }
                        </button>
                        <br></br><br></br>

                        <button type="button" onClick={() => resendOtp()} className="btn btn-secondary form-control">
                            {resend ?
                                <>
                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    Processando
                                </>
                                :
                                "Reenviar o código"
                            }
                        </button>
                        <button type="button" onClick={() => history.replace("/individual/pre-registo")} className="btn  form-control">Cancelar</button>

                    </div>
                    {/* <div> */}
                    {onerror && <div class="alert alert-danger" role="alert" style={{ marginTop: 10 }}>
                        {message}
                    </div>}

                    {onresend && <div class="alert alert-success" role="alert" style={{ marginTop: 10 }}>
                        {message}
                    </div>}
                    {/*   </div> */}
                </div>
            </div>
        </div>
    )
}

export default ComfirmOtp
