import React from 'react'
import '../components/confirm.css'
import { Link } from "react-router-dom";
function NotAvailable() {
    return (
        <div className="parent">
            <div className="p-4 mt-4 text-center">
                <div className="alert alert-danger text-center" role="alert" style={{ maxWidth: 600 }}>
                    Para a fase em curso o pré-registo já foi encerrado.<p/>
                    Assim que estiver disponível será anunciado através dos diversos meios de comunicação.
                </div>

                <Link className="btn btn-primary" to="/"> Voltar à página inicial </Link>
            </div>
        </div>
    )
}

export default NotAvailable
