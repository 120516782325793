import React, { useState, useEffect } from 'react'
import './Header.css'
import { Form } from 'react-bootstrap'
import config from '../config.json'
import { format, subYears } from 'date-fns'
import { IconButton } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { request, Acess } from '../settings'

function HeaderAction({ fieldsHeader, onChangeFilter }) {
    const [allAttributes, setallAttributes] = useState([])
    const [startDate, setstartDate] = useState(format(new Date(), "yyyy-MM-dd"))
    const [endDate, setendDate] = useState(format(new Date(), "yyyy-MM-dd"))
    const [provincia, setprovincia] = useState("")
    const [provincias, setprovincias] = useState([])
    const [distrito, setdistrito] = useState("")
    const [distritos, setdistritos] = useState([])
    const [localidade, setlocalidade] = useState("")
    const [localidades, setlocalidades] = useState([])
    const [loadingOU, setloadingOU] = useState(false)

    useEffect(() => {
        name()
    }, [])

    async function name() {
        let orgUnits = await Promise.resolve(request().getOrgUnits("level=province")).catch(x => {
            console.log("bug")
        })
        setprovincias(orgUnits?.data?.orgUnits)
    }

    async function getOrgUnitChanger(value, type) {
        setloadingOU(true)
        if (type === "provincia") {
            if (value === "") {
                setdistrito("")
                setdistritos([])
                setlocalidade("")
                setlocalidades([])
                setloadingOU(false)
                setprovincia("")
            } else {
                let orgUnits = await Promise.resolve(request().getOrgUnits("level=district&parent=" + value))
                setprovincia(value)
                setdistritos(orgUnits.data.orgUnits)
                setloadingOU(false)
            }
        } else if (type === "distrito") {
            if (value === "") {
                setdistrito(value)
                setlocalidade("")
                setlocalidades([])
                setloadingOU(false)
            } else {
                let orgUnits = await Promise.resolve(request().getOrgUnits("level=facility&parent=" + value))
                setlocalidades(orgUnits.data.orgUnits)
                setloadingOU(false)
                setdistrito(value)
            }
        } else if (type === "facility") {
            if (value === "") {
                setlocalidade("")
                setloadingOU(false)
            } else {
                setlocalidade(value)
                setloadingOU(false)
                setdistrito(value)
            }
        }
    }

    function verifyVisibility(id) {
        let a = true
        config.esconderNewConfig.forEach(x => {
            if (x === id) {
                a = false
            }
        });
        return a
    }
    function organize(value, i) {
        let attributes = [...fieldsHeader]
        attributes[i].trackedEntityAttribute = ({ ...attributes[i].trackedEntityAttribute, "value": value })
        setallAttributes(attributes)
    }

    function verifyLng(element) {
        // return element.trackedEntityAttribute.translations.filter(x => x.locale === lg)[0]?.value ? element.trackedEntityAttribute.translations.filter(x => x.locale === lg)[0]?.value : element.trackedEntityAttribute.displayName
        return element.trackedEntityAttribute.displayName
    }

    function verifyLngOpSet(x) {
        // return x?.translations?.filter(x => x.locale === lg)[0]?.value ? x?.translations?.filter(x => x.locale === lg)[0]?.value : x?.displayName
        return x?.displayName
    }

    return (
        <div className="d-flex">
            <div className="row justify-content-center">

                <Form.Control as="select"
                    style={{ width: 300, marginLeft: 20, margin: 10 }}
                    value={provincia} onChange={(e) => getOrgUnitChanger(e.target.value, "provincia")}>
                    <option value={""}>Selecione a Provincia</option>
                    {provincias?.map((x) =>
                        <option key={x?.id} value={x?.id}>{x.name}</option>
                    )}
                </Form.Control>

                <Form.Control
                    style={{ width: 300, marginLeft: 20, margin: 10 }}
                    as="select" value={distrito} onChange={(e) => getOrgUnitChanger(e.target.value, "distrito")}>
                    <option value={""}>Selecione o distrito</option>
                    {distritos?.map((x) =>
                        <option key={x?.id} value={x?.id}>{x.name}</option>
                    )}
                </Form.Control>

                <Form.Control
                    style={{ width: 300, marginLeft: 20, margin: 10 }}
                    as="select" required value={localidade} onChange={(e) => (setlocalidade(e.target.value, "facility"))}>
                    <option value={""}>Selecione a localidade</option>
                    {localidades?.map((x) =>
                        <option key={x?.id} value={x?.id}>{x.name}</option>
                    )}
                </Form.Control>

                {loadingOU && <div className="m-2 text-center"><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></div>}

                <Form.Control
                    onChange={e => setstartDate(e.target.value)}
                    id={"startDate"}
                    value={startDate}
                    style={{ width: 180, marginLeft: 20, margin: 10 }}
                    type={"DATE"} formTarget={"yyyy-MM-dd"}
                    placeholder={"Periodo Inicial"}
                />
                <Form.Control
                    onChange={e => setendDate(e.target.value)}
                    id={"endDate"}
                    value={endDate}
                    style={{ width: 180, marginLeft: 20, margin: 10 }}
                    type={"DATE"} formTarget={"yyyy-MM-dd"}
                    placeholder={"Periodo Final"}
                />

                {/* </Form> */}

            </div>
            <div className="mr-auto">
                <IconButton style={{ marginTop: 0, marginLeft: 20 }} onClick={() => onChangeFilter(allAttributes, startDate, endDate,  localidade!==""?localidade: distrito!==""?distrito: provincia)}><ArrowForward color="primary" /></IconButton>
                {/* <a  className="button-up" >Atualizar</a> */}
            </div>

        </div >
    )
}

export default HeaderAction
