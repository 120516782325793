import React, { useState } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { Application, MenuRoutes } from '../containers'
import { Login, PreReg, ShowId } from '../components'
import { AuthContext } from './authContext';
import PrivateRoute from './PrivateRoute';
import RedirectMain from './RedirectMain';


function Routes(props) {
    const [authTokens, setAuthTokens] = useState(null);
    const myStorage = window.sessionStorage;
    const { history } = props;
    const setTokens = (data) => {
        myStorage.setItem('token', JSON.stringify(data));
    };
    return (
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
            <Router>
                <Application history={history}>
                    <Switch>
                        <Route exact path="/pre-registo">
                            <PreReg />
                        </Route>
                        <Route exact path="/meu-codigo/:id">
                            <ShowId />
                        </Route>
                        <RedirectMain path="/login" component={Login} />
                        <PrivateRoute path="/Menu" component={MenuRoutes} />
                    </Switch>
                </Application>
            </Router>
        </AuthContext.Provider>
    )
}

export default Routes
