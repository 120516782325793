import React from 'react'
import { Link } from 'react-router-dom'
import QRCode from 'qrcode.react'
import { Acess } from '../settings'
import './card.css'


let data = []
let Url = ""
if (localStorage.getItem("me")) {
    data = ""
}

function Cards(props) {
    const { dataL, t } = props

    data = dataL?.length > 0 ? dataL : data
    Url = "https://stp-covid-vaccine-printer.herokuapp.com/?reference="+ data[0]?.attributes.find(x => x.attribute === Acess().codigo).value + "&phone=" + data[0]?.attributes.find(x => x.attribute === Acess().contacto).value

    return (
        <section id="featured" className="featured">
            <div className="container" >
                <div className="" data-aos="fade-up">
                    <div>
                        <div className="card-sl">
                            <div className="card-image" style={{ padding: 7 }}>
                                <QRCode value={Url} style={{ width: "100%", height: "100%" }} />
                            </div>
                            <div className="card-heading">
                                {t("Covid19vaccine")}
                            </div>
                            <div className="card-text d-flex p-2">
                                {t("CovidMessageCard")}
                            </div>
                            <Link onClick={() => window.open(Url, "_blank")} className="text-center mt-1 mt-lg-0">
                                <a className="card-buttonPrint">{t("Downloaddigitalcertificate")}</a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Cards
