import axios from 'axios';

export const authetication = btoa("sd:sd4Ever!");

const url = "https://apps.hisplp.org/stpapi/api"

const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNGU1N2ExYjE3ZTMwYzZmNTgwYWZiODA3ZmNlNzY2OGU3ODVkNDQwNDAzY2JjYTMzNjQ2NDJmMzAxZmQ3MjM5NjNmMTA0MzJiMGFlNDJiYTUiLCJpYXQiOjE2MzAwNjkyNTMuNTc4NTIzLCJuYmYiOjE2MzAwNjkyNTMuNTc4NTI5LCJleHAiOjE2NjE2MDUyNTMuNTY5MzI1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.bglkkPMmMC-2B10cj_2vztrtkJWgb5h0NmULJpwfXCTT3D9cJfkVZK8YCi6ZKi_ITcPabiS1GAOhsJcTfmZP4Lfa3CpzvRd6e56_tgd66pbfgTtDcb00VdoeD0PembnxQBf7FJ7q3kAJNReGuhcGaaW2jeoMuxr3_I_oYLqQpCmc6ANt_xeXSUQmh5R2pyD4FXWvOB_9SaL_FRq9f1tSd1eZvKNyaRIGoueUnl2zyUgroBILBYBWPdb130TWRoHXjCr-DNQK5Lfpxc8PWNKEfPpiBNOwU6L7r3SMjzW8oPR72FNfndj1Q4r4svFpiYAZHJCaWdCtZg7kKZw4CIK7z_U8RuVKrW_Rp7E7dECXaHzHUFtN47BLtDYgsYsf_ptHWB9t5Rm9T9eJzz22QzW_3UC8jyb8MHEah0HwjA1LWQpClejqIWkO7Bg2UcmdtcA14y6KCrN6cSaltBxUgRYY8yytzvW88YUNzZzWj7ftMdQyOvK_0rmKFdqQC9GxJD6PX_oS_bRFyhKikj64evWp00RC8O3b1a29uLueTkSktD4ZMtM13LFahzGE7ocPBaYJ5hfnsBLWFlp0vs_KE70zlO0G5C8Jj_j6iETbhBeu8rAwHfuVSNYFVXj109H1UmjIt12BitWfUtWzpipWH86Ow93X8ZQoKHMWZNM4WDcJuGI"
export function auth(user, pass) {
  return btoa(user + ':' + pass);
}

export function requestHeaders(user, pass) {
  if (user && pass) {
    return {
      headers: {
        Accept: 'application/json',
        Authorization: 'Basic ' + auth(user, pass),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With',
        'Access-Control-Max-Age': '60'
      }
    };
  } else
    return {
      headers: {
        Accept: 'application/json',
        Authorization: 'Basic ' + authetication,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With',
        'Access-Control-Max-Age': '60'
      }
    };
}

export function requestHeadersBDaAux() {
  return {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With',
      'Access-Control-Max-Age': '60'
    }
  };
}

export function baseUrl() {
  if (process.env.NODE_ENV === 'production') {
    return `https://dhis2.ms.gov.st/sive/api/33`;
  } else {
    return 'https://apolo.hispmoz.org/covidst/api/33';
  }
}

export function baseUrlBDAux() {
  // if (process.env.NODE_ENV === 'production') {
  //     return `${window.location.origin}/${window.location.pathname.toString().split('/')[1]}/api/33`;
  // } else {
  return 'https://apps.hisplp.org/stpapi/api/covid/immunization';
  // }
}

export function Acess() {
  return {
    contacto: process.env.REACT_APP_contacto,
    codigo: process.env.REACT_APP_codigo,
    ou: process.env.REACT_APP_ou,
    sexo: process.env.REACT_APP_sexo,
    dataNasc: process.env.REACT_APP_dataNasc,
    nome: process.env.REACT_APP_nome,
    apelido: process.env.REACT_APP_apelido,
    email: process.env.REACT_APP_email,
    localidade: process.env.REACT_APP_localidade,
    grupoAlvo: process.env.REACT_APP_grupoAlvo,
    tipoDoc: process.env.REACT_APP_tipoDoc,
    numDoc: process.env.REACT_APP_numDoc,
    endereco: process.env.REACT_APP_endereco,

    programResultadoCovid: process.env.REACT_APP_programResultadoCovid,
    pStageResultadoCovid: process.env.REACT_APP_pStageResultadoCovid,
    resultadoCovid: process.env.REACT_APP_resultadoCovid,
    dataColetaCovid: process.env.REACT_APP_dataColetaCovid,

    programVacina: process.env.REACT_APP_programVacina,
    pStageVacina: process.env.REACT_APP_pStageVacina,
    doseNumbVacina: process.env.REACT_APP_doseNumbVacina,
    vacinaRecebida: process.env.REACT_APP_vacinaRecebida,
    vaccineManuf: process.env.REACT_APP_vaccineManuf,
    sugestao: process.env.REACT_APP_sugestao
  };
}

export function request() {
  return {
    getMyId: (id) => (axios.get(`${baseUrl()}/trackedEntityAttributes/${id}/generate.json?&_=${new Date().getTime()}`, requestHeaders())),
    getMe: (contacto, codigo) => (axios.get(`${baseUrl()}/trackedEntityInstances.json?ou=${Acess().ou}&program=${Acess().programVacina}&totalPages=true&ouMode=DESCENDANTS&fields=trackedEntityInstance,trackedEntityType,attributes[attribute,value,displayName]&filter=${Acess().contacto}:in:${contacto},${Acess().codigo}:in:${codigo}&?&_=${new Date().getTime()}`, requestHeaders())),
    getMyEvents: (teis) => (axios.get(`${baseUrl()}/trackedEntityInstances.json?trackedEntityInstance=${teis}&program=${Acess().programVacina}&fields=created,trackedEntityInstance,attributes[attribute,value]&?&_=${new Date().getTime()}`, requestHeaders())),
    getAllEventsVaccine: (page, pageSize, filter, programStartDate, programEndDate, ou) => (axios.get(`${baseUrl()}/trackedEntityInstances/query?ou=${ou === "" ? localStorage.getItem("ou") : ou}&program=${Acess().programVacina}&programStartDate=${programStartDate}&programEndDate=${programEndDate}&totalPages=true&ouMode=DESCENDANTS&fields=attributes[attribute,value],trackedEntityInstance,trackedEntityType,enrollments[orgUnitName,created,status,events[eventDate,event,lastUpdatedAtClient,programStage,dataValues[dataElement,value]]]&page=${page}&pageSize=${pageSize}&filter=${filter}&?&_=${new Date().getTime()}`, requestHeaders())),
    getSchedule: (page, pageSize, startDate, endDate, ou) => (axios.get(`${baseUrl()}/events.json?orgUnit=${ou === "" ? "PfzYLbYPo29" : ou}&program=${Acess().programVacina}&programStage=${Acess().pStageVacina}&startDate=${startDate}&endDate=${endDate}&totalPages=true&ouMode=SELECTED&fields=trackedEntityInstance,dueDate,orgUnitName&page=${page}&pageSize=${pageSize}&status=SCHEDULE&?&_=${new Date().getTime()}`, requestHeaders())),
    getAllEvents: (contacto, codigo) => (axios.get(`${baseUrl()}/trackedEntityInstances.json?ou=${Acess().ou}&totalPages=true&ouMode=DESCENDANTS&fields=enrollments[orgUnitName,created,status,events[event,lastUpdatedAtClient,programStage,program,dataValues[dataElement,value]]]&filter=${Acess().contacto}:in:${contacto},${Acess().codigo}:in:${codigo}&?&_=${new Date().getTime()}`, requestHeaders())),
    getProgramsStage: () => (axios.get(`${baseUrl()}/programStages.json?paging=false?&_=${new Date().getTime()}`, requestHeaders())),
    getPrograms: () => (axios.get(`${baseUrl()}/programs.json?&_=${new Date().getTime()}`, requestHeaders())),
    getMyPrograms: (contacto, codigo) => (axios.get(`${baseUrl()}/trackedEntityInstances.json?ou=${Acess().ou}&totalPages=true&ouMode=DESCENDANTS&fields=enrollments[events[program]]&filter=${Acess().contacto}:in:${contacto},${Acess().codigo}:in:${codigo}&?&_=${new Date().getTime()}`, requestHeaders())),
    getSection: (username, password) => (axios.get(`${baseUrl()}/me.json`, requestHeaders(username, password))),
    //getAttributes: () => (axios.get(`${baseUrl()}/programs/${Acess().programVacina}.json?fields=trackedEntityType[id],programStages[id,displayName,programStageDataElements[displayInReports,dataElement[id,displayName]]],programTrackedEntityAttributes[displayInList,searchable,trackedEntityAttribute[displayName,translations[locale,value],id,valueType,optionSet[options[code,id,displayName,translations[locale,value]]]]]?&_=${new Date().getTime()}`, requestHeaders())),
    getAttributes: () => (axios.get(`${baseUrl()}/metadata.json?fields=:owner&programs:filter=id:eq:${Acess().programVacina}&programs:fields=:id,displayName,trackedEntityType[id],programTrackedEntityAttributes[displayInList,searchable,trackedEntityAttribute[name,optionSet[options[code,id,displayName]],id,valueType,displayName,sortOrder]]?&_=${new Date().getTime()}`, requestHeaders())),
    getMyAttributes: (id) => (axios.get(`${baseUrl()}/trackedEntityInstances/${id}.json?program=${Acess().programVacina}&fields=attributes[attribute,value,displayName],trackedEntityInstance,orgUnit,trackedEntityType?&_=${new Date().getTime()}`, requestHeaders())),
    getMyOrgUnit: () => (axios.get(`${baseUrl()}/me.json?fields=organisationUnits[name,id,level]?&_=${new Date().getTime()}`, requestHeaders())),
    postU: (formUser, type) => (axios.post(`${baseUrlBDAux()}/register?provider=${type}`, formUser, requestHeadersBDaAux())),
    getOptionSets: () => (axios.get(`${baseUrl()}/programs.json?&_=${new Date().getTime()}`, requestHeaders())),
    getOrgUnits: (paramiter) => (axios.get(`${baseUrlBDAux()}/orgUnits?${paramiter}&type=public`, requestHeadersBDaAux())),
    postDateSchulder: (facility) => (axios.post(`${baseUrlBDAux()}/schedule`, facility, requestHeadersBDaAux())),
    getOtp: (numero) => (axios.get(`${url}/otp/send?phone=${numero}`, requestHeadersBDaAux())),
    getConfirmOtp: (numero, code) => (axios.get(`${url}/otp/check?phone=${numero}&code=${code}`, requestHeadersBDaAux())),
    sendSms: (message) => (axios.post(`${baseUrlBDAux()}/notification/sendSMS`, message, requestHeadersBDaAux())),
    sendEmail: (message) => (axios.post(`${baseUrlBDAux()}/notification/sendEmail`, message, requestHeadersBDaAux())),
    getAvailable: (paramiter) => (axios.get(`${baseUrlBDAux()}/schedules?${paramiter}`, requestHeadersBDaAux())),
    getHtmlPage: (tei) => (axios.get(`${url}/loginmAction?trackedEntityInstance=${tei}&certificateCheck=true`)),
    //getOrgUnits: (ou) => (axios.get(`${baseUrl()}/organisationUnits/${ou}.json?fields=id,displayName,children[id,displayName]?&_=${new Date().getTime()}`, requestHeaders())),
    // getSAaerchable: () => (axios.post(`${baseUrl()}/programs/yDuAzyqYABS.json?fields=programStages[id,displayName,programStageDataElements[displayInReports,dataElement[id,displayName]]],programTrackedEntityAttributes[displayInList,trackedEntityAttribute[id,name]]&paging=false`, requestHeaders())),
  };
}