import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { Button, Form, Col, Alert } from 'react-bootstrap'
import QRCode from 'qrcode.react'
import { Spin } from 'antd';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Pdf from "react-to-pdf";
import './confirm.css'
import { request } from '../settings'
import logo from '../img/stp.png'
import './ShowId.css'
let data = null

const ref = React.createRef();
let myAllObj = {}
let emailMessage = {}
function ShowId(props) {
    const { t, myAllData } = props
    const { i } = useParams()
    const [loading, setloading] = useState(true)
    const [qrContet, setqrContet] = useState({})
    const [onError, setonError] = useState(false)
    const [onsuccess, setonsuccess] = useState(false)
    const [message, setmessage] = useState("")
    const [sending, setsending] = useState(false)
    const [emailResend, setemailResend] = useState(false)
    const [showFieldEmail, setshowFieldEmail] = useState(false)
    const [email, setemail] = useState("")
    const [validated, setvalidated] = useState(false)



    Object.size = function (obj) {
        var size = 0,
            key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };

    useEffect(() => {
        getAttr()

    }, [])

    async function getAttr() {
        if (i) {
            myAllObj = JSON.parse(localStorage.getItem("localpersonSaving", JSON.stringify(myAllData)))?.[i]
        } else
            if (Object.size(myAllData) > 0) {
                localStorage.removeItem("myAllData")
                localStorage.setItem("myAllData", JSON.stringify(myAllData))
                myAllObj = myAllData
            } else {
                myAllObj = JSON.parse(localStorage.getItem("myAllData", JSON.stringify(myAllData)))
            }

        let dt = {
            "deleted": false,
            "organisationUnit": myAllObj.orgUnit,
            "state": "SYNCED",
            "trackedEntityType": myAllObj.trackedEntityType,
            "uid": myAllObj.trackedEntityInstance
        }

        emailMessage = {
            "identifier": myAllObj.identifier,
            "firstName": myAllObj.firstName,
            "surname": myAllObj.surname,
            "birthDate": myAllObj.birthDate,
            "qrCode": JSON.stringify(qrContet),
            "telecom": [
                {
                    "phone": myAllObj?.telecom?.[0]?.phone,
                    "email": myAllObj?.telecom?.[0]?.email
                }
            ],
            "immunization": [
                {
                    "managingOrganizationName": myAllObj.facilityName,
                    "scheduleDate": myAllObj.date,
                    "time": myAllObj.time
                }
            ]
        }

        data = ({
            "data": new Buffer.from(JSON.stringify(dt)).toString('base64'),
            "type": "TEI"
        })
        setqrContet(data)
        setloading(false)

        /*     function pdfPrinter() {
                const input = document.getElementById("divToDownload");
    
    
                // input.innerHTML = 'body{background-color: "#f5f5f5",width: "210mm",min - height: "297mm",margin - left: "auto",margin - right: "auto";} '
    
    
    
                html2canvas(input)
                    .then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
                        const pdf = new jsPDF();
                        pdf.addImage(imgData, 'JPEG', 0, 0);
                        pdf.save("Comprovativo de Pré Registo.pdf");
                    })
            }
     */
        /*       async function getAttr() {
                  let getMyAttribut = await Promise.resolve(request().getMyAttributes(local.id));
                  let dt = {
                      "deleted": false,
                      "organisationUnit": getMyAttribut.data.orgUnit,
                      "state": "SYNCED",
                      "trackedEntityType": getMyAttribut.data.trackedEntityType,
                      "uid": getMyAttribut.data.trackedEntityInstance
                  }
                  data = ({
                      "data": new Buffer.from(JSON.stringify(dt)).toString('base64'),
                      "type": "TEI"
                  })
                  setqrContet(data)
                  // console.log(data)
                  getMyAttribut.data.attributes.forEach(x => {
                      if (x.attribute === "ENRjVGxVL6l") {
                          setlastname(x.value)
                      }
                      if (x.attribute === "sB1IHYu2xQT") {
                          setname(x.value)
                      }
                      if (x.attribute === "KSr2yTdu1AI") {
                          setuniqueId(x.value)
                      }
                      if (x.attribute === "fctSQp5nAYl") {
                          setcontacto(x.value)
                      }
                      if (x.attribute === "oindugucx72") {
                          setsexo(x.value)
                      }
                      if (x.attribute === "Ewi7FUfcHAD") {
                          setbi(x.value)
                      }
                      // if (x.attribute === "CklPZdOd6H1") {
                      //     setnacional(x.value)
                      // }
                      if (x.attribute === "mAWcalQYYyk") {
                          setdataNasc(x.value)
                      }
      
                  });
      
                  setloading(false)
      
              } */

        if (loading) {
            return (
                <div style={{ height: '100vh' }} >
                    <Spin size="large" style={{ position: 'fixed', top: '50%', left: '50%' }}></Spin>
                </div>
            )
        }
    }


    function emailVerify(event) {
        const form = event ? event.currentTarget : "";
        setvalidated(true);
        if (form.checkValidity() === false && showFieldEmail) {
            event.preventDefault();
            event.stopPropagation();
        } else {

            emailMessage = {
                "identifier": myAllObj.identifier,
                "firstName": myAllObj.firstName,
                "surname": myAllObj.surname,
                "birthDate": myAllObj.birthDate,
                "qrCode": JSON.stringify(qrContet),
                "telecom": [
                    {
                        "phone": myAllObj?.telecom?.[0]?.phone,
                        "email": email
                    }
                ],
                "immunization": [
                    {
                        "managingOrganizationName": myAllObj.facilityName,
                        "scheduleDate": myAllObj.date,
                        "time": myAllObj.time
                    }
                ]
            }

            event.preventDefault();
            event.stopPropagation();
            emailSender()
        }
    }

    async function emailSender() {
        email ? setemailResend(true) : setsending(true);
        if (!myAllObj?.telecom?.[0]?.email && email.length === 0) {
            setonError(true)
            setemailResend(false)
            //Quando a pesssoa não registou seu email
            setmessage("Não registou o seu email previamente, por favor preencha o seu email abaixo")
            setshowFieldEmail(true)
            setsending(false);
            setTimeout(() => {
                setonError(false)
            }, 10000)

        } else {
            let emailSended = await Promise.resolve(request().sendEmail(emailMessage)).catch(x => {
                email ? setemailResend(false) : setsending(false);
                setonError(true);
                setmessage("Problemas na configuração do email, tente novamente");
                setTimeout(() => {
                    setonError(false)
                }, 10000)
            })

            if (emailSended?.data?.status === 200) {
                email ? setemailResend(false) : setsending(false);
                setonsuccess(true)
                setemail("")
                setshowFieldEmail(false)
                email ? setmessage("Email enviado com sucesso para: " + email) : setmessage("Email enviado com sucesso para: " + myAllObj?.telecom?.[0]?.email)

                setTimeout(() => {
                    setonsuccess(false)
                }, 10000)
            } else if (emailSended?.data?.status === 400) {
                email ? setemailResend(false) : setsending(false);
                setmessage(emailSended?.data?.message)
                setonError(true)
                setTimeout(() => {
                    setonError(false)
                }, 10000)
            }
        }
    }

    if (loading) {
        return (
            <div className="spin-loader" >
                <Spin size="large"></Spin>
            </div>
        )
    }

    if (qrContet.hasOwnProperty("data")) {
        return (
            <>
                <div className="hidden-divice">
                    <div className="p-4" ref={ref} id="divToDownload">
                        <div className="text-center mb-4">
                            <img width={220} src={logo} alt="" className="img-fluid" />
                            <h4>República Democrática de São Tomé e Príncipe</h4>
                            <h4>Ministério da Saúde</h4>
                            <p />
                            <h5>Comprovativo de Pré-Registo</h5>
                            {/* <h5>VERSÃO DE TESTE, NÃO APLICÁVEL PARA A VACINAÇÃO</h5> */}
                        </div>
                        <div class="alert alert-primary text-center" role="alert">
                            Os seus dados de acesso são: <strong>Contacto</strong> e <strong>Referencia</strong>
                        </div>
                        <div className=" container justify-content-center">
                            <div className="col-md-12 d-flex">
                                <div className="col-md-5 col-12">
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <strong>Referencia:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.identifier}</h7>
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <strong>{myAllObj.nationalIdType}:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.nationalId}</h7>
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <strong>Primeiro Nome:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.firstName}</h7>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <strong>Contacto:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj?.telecom?.[0]?.phone}</h7>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <strong>Local:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.facilityName}</h7>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-5">

                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-6">
                                            <strong>Sexo:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.gender === "Male" ? "Masculino" : "Feminino"}</h7>
                                        </div>
                                    </div>

                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-6">
                                            <strong>Data de Nascimento:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.birthDate}</h7>
                                        </div>
                                    </div>

                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-6">
                                            <strong>Apelido:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.surname}</h7>
                                        </div>
                                    </div>
                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-6">
                                            <strong>Data:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.date}</h7>
                                        </div>
                                    </div>
                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-6">
                                            <strong>Hora:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.time}</h7>
                                        </div>
                                    </div>
                                </div>
                                <QRCode value={JSON.stringify(qrContet)} size={180} />
                            </div>
                        </div>

                    </div>
                    <div class="text-center">
                        {onsuccess && <div className="parent container mb-1">
                            <Alert variant="success" className="text-center ">
                                {message}
                            </Alert>
                        </div>
                        }
                        {(onError) ?
                            <div className="parent container">
                                <Alert variant="danger" className="text-center ">
                                    {message}
                                </Alert>
                            </div>
                            :
                            null
                        }
                        <Button className="btn-success ml-2" onClick={() => window.print()} >
                            Baixar/Imprimir Comprovativo <i class="bi bi-align-bottom"></i>
                        </Button>
                        {' '}
                        <Button className="btn-primary" onClick={() => emailSender()} >
                            {sending ?
                                <>
                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    {t("Processing")}
                                </>
                                :
                                "Enviar Comprovativo por e-mail"
                            }
                        </Button>
                        {' '}
                        <Button className="btn-warning" onClick={() => window.open("https://forms.gle/oAfyBv9yV8Hth3nk6", "_blank")} >
                            Preencher o formulário de avaliação do pré-registo
                        </Button>





                        {(showFieldEmail) ?
                            <Form noValidate onSubmit={emailVerify} className="parent">
                                <span className="center-block">
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            required
                                            onChange={e => setemail(e.target.value)}
                                            key={"email"}
                                            value={email}
                                            type={"email"}
                                            placeholder={"Email"}
                                            isInvalid={validated && !/^([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4})$/.test(email) ? true : false}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Introduza um email válido
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button className="btn-primary" type={"submit"} >
                                        {emailResend ?
                                            <>
                                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                {t("Processing")}
                                            </>
                                            :
                                            "Enviar"
                                        }
                                    </Button>
                                </span>
                            </Form>
                            :
                            null
                        }

                    </div>
                </div>

                <div className="hidden-pc">
                    <div className="p-4" ref={ref} id="divToDownload">
                        <div className="text-center mb-4">
                            <img width={80} src={logo} alt="" className="img-fluid" />
                            <h4>República de Moçambique</h4>
                            <h4>Ministério da Saúde</h4>
                            <p />
                            <h5>Comprovativo de Pré-Registo</h5>
                            {/* <h5>VERSÃO DE TESTE, NÃO APLICÁVEL PARA A VACINAÇÃO</h5> */}
                        </div>
                        <div class="alert alert-primary text-center" role="alert">
                            Os seus dados de acesso são: <strong>Contacto</strong> e <strong>Referencia</strong>
                        </div>
                        <div className=" container justify-content-center">
                            <div className="col-md-12 d-flex">
                                <div className="col-md-6">
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <strong>Referencia:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.identifier}</h7>
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <strong>{myAllObj.nationalIdType}:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.nationalId}</h7>
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <strong>Primeiro Nome:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.firstName}</h7>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <strong>Contacto:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj?.telecom?.[0]?.phone}</h7>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <strong>Local:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.facilityName}</h7>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-6">

                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-6">
                                            <strong>Sexo:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.gender === "Male" ? "Masculino" : "Feminino"}</h7>
                                        </div>
                                    </div>



                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-6">
                                            <strong>Data de Nascimento:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.birthDate}</h7>
                                        </div>
                                    </div>

                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-6">
                                            <strong>Apelido:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.surname}</h7>
                                        </div>
                                    </div>

                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-6">
                                            <strong>Data:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.date}</h7>
                                        </div>
                                    </div>

                                    <div className="row mb-4 ms-2">
                                        <div className="col-md-6">
                                            <strong>Hora:</strong>
                                        </div>
                                        <div className="col-md-6 text-secondary">
                                            <h7 className="mb-0">{myAllObj.time}</h7>
                                        </div>
                                    </div>


                                </div>

                            </div>
                            <div className="text-center">
                                <QRCode value={JSON.stringify(qrContet)} size={180} />
                            </div>
                        </div>

                    </div>
                    <div class="text-center">
                        {onsuccess && <div className="parent container mb-3">
                            <Alert variant="success" className="text-center">
                                {message}
                            </Alert>
                        </div>
                        }
                        {(onError) ?
                            <div className="parent container">
                                <Alert variant="danger" className="text-center ">
                                    {message}
                                </Alert>
                            </div>
                            :
                            null
                        }

                        <Button className="btn-success mb-2 ml-2" onClick={() => window.print()} >
                            Baixar/Imprimir Comprovativo
                        </Button>

                        <Button className="btn-primary mb-2" onClick={() => emailSender()} >
                            {sending ?
                                <>
                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    {t("Processing")}
                                </>
                                :
                                "Enviar Comprovativo por e-mail"
                            }
                        </Button>

                        <Button className="btn-warning" onClick={() => window.open("https://forms.gle/oAfyBv9yV8Hth3nk6", "_blank")} >
                            Preencher o formulário de avaliação do pré-registo
                        </Button>


                        {(showFieldEmail) ?
                            <Form noValidate onSubmit={emailVerify} className="parent">
                                <span className="center-block">
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            required
                                            onChange={e => setemail(e.target.value)}
                                            key={"email"}
                                            value={email}
                                            type={"email"}
                                            placeholder={"Email"}
                                            isInvalid={validated && !/^([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4})$/.test(email) ? true : false}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Introduza um email válido
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button className="btn-primary" type={"submit"} >
                                        {emailResend ?
                                            <>
                                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                {t("Processing")}
                                            </>
                                            :
                                            "Enviar"
                                        }
                                    </Button>
                                </span>
                            </Form>
                            :
                            null
                        }

                    </div>
                </div>

            </>
        )
    } else {
        return (
            <div className="spin-loader">
                <Spin size="large"></Spin>
            </div>
        )
    }

}

export default ShowId