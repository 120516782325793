import React, { useState, Suspense } from "react";
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { RedirectMain, PrivateRoute, PrivateRouteIndividual, RedirectMainIndividual, RouteChangeTracker } from './containers'
import { AuthContext } from './containers/authContext';
import './App.css'
import ReactGA from 'react-ga';
import { Spin } from 'antd';
// import VaccinationCertificate from "./components/Prints/VaccinationCertificate";



const TRACKING_ID = "UA-205063558-1";
ReactGA.initialize(TRACKING_ID);

function RouteWrapperSO1L({
  component: Component,
  layout: Layout,
  t,
  lg,
  setmyAllData,
  myAllData,
  ...rest
}) {
  return (
    <Route {...rest} render={(props) =>
      <Layout t={t} {...props}>
        <Component t={t} lg={lg} setmyAllData={setmyAllData} myAllData={myAllData} {...props} />
      </Layout>
    } />
  );
}
export default function App() {
  const [authTokens, setAuthTokens] = useState(null);
  const [dataLogin, setdataLogin] = useState(null);
  const [myAllData, setmyAllData] = useState({})
  const [lg, setlg] = useState("pt")
  const myStorage = window.sessionStorage;
  const { t } = useTranslation();
  // const [type, type] = useState()


  const setTokens = async (data) => {
    if (data[0].type === "person") {
      await myStorage.setItem('person', JSON.stringify(data));
    } else
      await myStorage.setItem('token', JSON.stringify(data));
  };
  // console.log(dataLogin)


  const Application = React.lazy(() => import('./containers/Application'));
  const Home = React.lazy(() => import('./containers/Home'));
  const Menu = React.lazy(() => import('./containers/Menu'));
  const PreReg = React.lazy(() => import('./components/PreRegisto'));
  const ShowId = React.lazy(() => import('./components/ShowId'));
  const Login = React.lazy(() => import('./components/Login'));
  const ComfirmOtp = React.lazy(() => import('./components/ComfirmOtp'));
  const Certificate = React.lazy(() => import('./containers/Certificate'));
  const VaccinationCertificate = React.lazy(()=> import('./components/Prints/VaccinationCertificate'))

  return (

    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens, setlogindata: setdataLogin, setlng: setlg }}>
      <Router className="App">
        <RouteChangeTracker />
        <Suspense fallback={<div className="spin-loader" ><Spin size="large"></Spin></div>}>
          <Switch>
            <RouteWrapperSO1L t={t} exact path="/" component={Home} layout={Application} />
            <PrivateRoute t={t} lg={lg} exact path="/pre-registo" component={PreReg} layout={Application} />
            <RouteWrapperSO1L t={t} lg={lg} setmyAllData={setmyAllData} exact path="/individual/pre-registo" component={PreReg} layout={Application} />
            <PrivateRoute t={t} lg={lg} exact path="/edit/:id" component={PreReg} layout={Application} />
            <PrivateRoute t={t} lg={lg} exact path="/meu-codigo/:id" component={ShowId} layout={Application} />
            <RouteWrapperSO1L t={t} lg={lg} exact path="/certificate/:id" component={Certificate} layout={Application} />
            <RouteWrapperSO1L t={t} lg={lg} setmyAllData={setmyAllData} myAllData={myAllData} exact path="/individual/meu-codigo" component={ShowId} layout={Application} />
            <RouteWrapperSO1L t={t} lg={lg} exact path="/comfirmacao/:i" component={ShowId} layout={Application} />
            <RedirectMain t={t} exact path="/login" component={Login} layout={Application} />
            <RedirectMainIndividual t={t} exact path="/individual/login" component={Login} layout={Application} />
            <PrivateRoute t={t} lg={lg} data={dataLogin} exact path="/menu" component={Menu} layout={Application} />
            <PrivateRouteIndividual t={t} lg={lg} data={dataLogin} exact path="/individual/menu" component={Menu} layout={Application} />
            <RouteWrapperSO1L t={t} lg={lg} data={dataLogin} myAllData={myAllData} setmyAllData={setmyAllData} exact path="/individual/confirm" component={ComfirmOtp} layout={Application} />
            <PrivateRoute t={t} lg={lg} data={dataLogin} exact path="/analytics" component={Menu} layout={Application} />
            {/* <PrivateRoute data={dataLogin} exact path="/menu/vacinas" component={TesteCovid} layout={Menu} layout1={ApplicationComp} /> */}
            <Route exact path="/vaccination/certificate/print/:tei" component={VaccinationCertificate} />
            <RouteWrapperSO1L t={t} component={Home} layout={Application} />
          </Switch>
        </Suspense>
      </Router>
    </AuthContext.Provider>
  );
}