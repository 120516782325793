import React, { useEffect, useState } from 'react'
import { request } from '../settings'
import { useParams } from 'react-router-dom'
import { Spin } from 'antd';

function Certificate() {
    const { id } = useParams()
    const [template, settemplate] = useState("")
    const [loading, setloading] = useState(true)
    async function getTamplate(tei) {
        let htmlFilePage = await Promise.resolve(request().getHtmlPage(tei)).catch(x => console.log(x))
        settemplate(htmlFilePage?.data)
        setloading(false)
    }
    useEffect(() => {
        getTamplate(id)
    }, [])

    if (loading) {
        return (
            <div className="spin-loader" >
                <Spin size="large"></Spin>
            </div>
        )
    }

    return (
        <div style={{ backgroundColor: "#f0f3f5" }}>
            <div dangerouslySetInnerHTML={{ __html: template }} />
        </div>
    )
}

export default Certificate
